import { IonButtons, IonContent, IonHeader, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonTextarea, IonButton } from '@ionic/react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import './Cibo.css';

const AddCibo: React.FC = () => {

  const name = "Cibo";
  const fileInput = useRef(null);
  let history = useHistory();
  const [FileName, setFileName] = useState<string>();
  const onSelectFile = function () {
    document.getElementById("file-btn").hidden = true;
    document.getElementById("file-remove").style.display = "block";
    setFileName(fileInput?.current?.value.split('\\').pop());
  }
  const onRemoveFile = function () {
    document.getElementById("file-remove").style.display = "none";
    document.getElementById("file-btn").hidden = false;
    var inputname = document.getElementById("file-name") as HTMLInputElement;
    var inputfield = document.getElementById("file-input") as HTMLInputElement;
    inputname.value = "";
    inputfield.value = "";
    setFileName(null);
    document.getElementById("file-btn").removeAttribute("disabled");    
  }
  const formSubmit = function (e) {
    SubmitCibo();
    e.preventDefault();
  }

  async function SubmitCibo () {    
    const logoval = fileInput?.current?.files[0];
    const name = (document.getElementById("name-input") as HTMLInputElement).value;
    const address = (document.getElementById("address-input") as HTMLInputElement).value;
    const desc = (document.getElementById("desc-input") as HTMLInputElement).value;
    const ldesc = (document.getElementById("ldesc-input") as HTMLInputElement).value;

    const formData = new FormData();
    formData.append('logo', logoval);
    formData.append('name', name);
    formData.append('desc', desc);
    formData.append('longdesc', ldesc);
    formData.append('address', address);
    formData.append('req', 'addRestaurant');


    const requestOptions = {
      method: 'POST',
      body: formData
      //"req=addRestaurant&name="+name+"&desc="+desc+"&longdesc="+ldesc+"&address="+address+"&logo="+logoval
    };    
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(()=>{  history.push("/page/Cibo");});
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList><form id="add-cibo-form" onSubmit={formSubmit}>
          <IonItem>
            <IonLabel position="fixed">Nome</IonLabel>
            <IonInput id="name-input" required></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="fixed">Indirizzo</IonLabel>
            <IonInput id="address-input"></IonInput>
          </IonItem>
          <IonItem>
            <IonInput placeholder="Breve descrizione" id="desc-input" required></IonInput>
          </IonItem>
          <IonItem>
            <IonTextarea placeholder="Descrizione accattivante" id="ldesc-input"></IonTextarea>
          </IonItem>
          <IonItem>
            <IonLabel position="fixed">Logo</IonLabel>
            <input
              ref={fileInput}
              hidden
              type="file"
              id="file-input"
              accept="image/png, image/jpeg"
              onChange={onSelectFile}
              onClick={() => {
                console.log('onClick');
              }}
            />
            <IonButton
              color="primary"
              id="file-btn"
              onClick={() => {
                // @ts-ignore
                fileInput?.current?.click();
                // setBackgroundOption(BackgroundOptionType.Gradient);
              }}>
              Scegli File
            </IonButton>
            <IonInput value={FileName} id="file-name" readonly ></IonInput>
            <IonButton id="file-remove" onClick={onRemoveFile} style={{display: "none"}} >Rimuovi</IonButton>
          </IonItem>
          <IonButton type="submit" expand="block">Aggiungi</IonButton></form>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default AddCibo;
