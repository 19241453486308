// Events Functions

import { ReadSetting } from "./LocalData";

export async function GetGroupEvents(gid) {
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
        body: "req=getEvents&group="+gid
    }; 
    var data = undefined;
    await fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json()).then((res) => {console.log(res); data=res; });
    return data;
    
}

export async function GetGroupEventsAdvanced(gid, timeFilter) {   

    const requestBody = new FormData();
    requestBody.append('req', "getEventsAdvanced");
    requestBody.append('group', gid);
    requestBody.append('time', timeFilter);
    if(gid == 'user') requestBody.append('user', ReadSetting("UserName"));

    const requestOptions = {
        method: 'POST',
        body: requestBody
    }; 
    var data = undefined;
    await fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json()).then((res) => {console.log(res); data=res; });
    return data;
    
}

export async function GetNSetGroupEvents(gid, SetFunction){
    SetFunction(await GetGroupEvents(gid));
}