import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import React from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { LoadUserSettingsDetails, LoadUserSettingsColors } from '../components/loadSettings';
import './User.css';


const UserSettingsDetails: React.FC = () => {
  const { name } =  useParams<{ name: string; }>();
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log('Begin async operation');
    window.location.reload();
  
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {(name == "Colors") ?
        <LoadUserSettingsColors/>
        :
        <LoadUserSettingsDetails Category={name} />  }
      </IonContent>
    </IonPage>
  );
};

export default UserSettingsDetails;
