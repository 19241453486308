import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import React from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { LoadGroupSettings } from '../components/loadSettings';
import './Group.css';

interface GroupDetailPageProps extends RouteComponentProps<{
  [gid: string]: string;
}> {}

const GroupSettings: React.FC<GroupDetailPageProps> = ({match}) => {
  const name = "GroupSettings";
  const gid = (match.params.gid != undefined) ? match.params.gid : new URL(window.location.href).pathname.split('/')[2];

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log('Begin async operation');
    window.location.reload();
  
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <LoadGroupSettings id={gid}/>  
      </IonContent>
    </IonPage>
  );
};

export default GroupSettings;
