import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import React from 'react';
import './Cibo.css';
import LoadDettagli from '../components/loadDettagli';

interface UserDetailPageProps extends RouteComponentProps<{
  [id: string]: string;
}> {}

const Dettagli: React.FC<UserDetailPageProps> = ({match}) => {
  const name = "Dettagli";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <LoadDettagli id={match.params.id} group={match.params.gid} />                 
      </IonContent>
    </IonPage>
  );
};

export default Dettagli;
