// User Edit Functions
import { DeleteSetting, ReadSetting, WriteSetting } from "./LocalData";
import Compressor from "compressorjs";

export function EditUserInfo(uid, title, desc) {
    const requestOptions = {
    method: 'POST',
    headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
    body: "req=editUserDetails&id="+uid+"&title="+title+"&desc="+desc
    };  
    
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
    
}
export function SyncUserImage() {
    const Username = ReadSetting("UserName");
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
        body: "req=getUserImage&id="+Username
      };
      fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.text()).then(data => { WriteSetting("UserImage", "https://api.vpwi.it/cugust/assets/img/user-imgs/"+data) });      
}
export function EditUserImage(uid, avatar) {
    new Compressor(avatar, {
        quality: 0.8,
        convertSize: 2000000,
    
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {            
            const formData = new FormData();

            // The third parameter is required for server
            // @ts-ignore: La proprietà 'name' non esiste nel tipo 'Blob
            formData.append('avatar', result, result.name);
            
            formData.append('id', uid);
            formData.append('req', 'editUserImage');

            const requestOptions = {
                method: 'POST',
                body: formData
            };    
            fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
        },
        error(err) {
            console.log(err.message);
        },
      });    
}

export function SyncUserSettings(){    
    const Username = ReadSetting("UserName");
    SyncUserImage()
    const requestOptions2 = {
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
        body: "req=getUserSettings&id="+Username
      };
      return fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions2)
      .then(response => response.json())
      .then(settings => WriteSetting("UserSettings", JSON.stringify(settings)));
}

export function GetUserSettings(){    
    const Username = ReadSetting("UserName");
    const requestOptions2 = {
        method: 'POST',
        headers: new Headers({
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }),
        body: "req=getUserSettings&id="+Username
      };
      return fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions2).then(response => response.json());      
}

export function EditUserSetting(SetName, newValue){
    var settings = JSON.parse(ReadSetting("UserSettings"));
    settings[SetName] = newValue;
    WriteSetting("UserSettings", JSON.stringify(settings));

    const Username = ReadSetting("UserName");
    const vars = new FormData();
    vars.append('req', "editUserSettings");
    vars.append('id', Username);
    vars.append('setName', SetName);
    vars.append('setVal', newValue);
    const requestOptions = {
      method: 'POST',
      body: vars
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.text()).then((res) => console.log(res));
}

export function EditUserColor(newValue){
    const SetName = "Color";
    var settings = JSON.parse(ReadSetting("UserSettings"));
    settings[SetName] = newValue;
    WriteSetting("UserSettings", JSON.stringify(settings));

    const Username = ReadSetting("UserName");
    const vars = new FormData();
    vars.append('req', "editUserSettings");
    vars.append('id', Username);
    vars.append('setName', SetName);
    vars.append('setVal', newValue);
    const requestOptions = {
      method: 'POST',
      body: vars
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.text()).then((res) => console.log(res));
}

export function AddUserMember(gid, uid) {
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
        body: "req=addUserMembers&id="+gid+"&userid="+uid
    };  
        
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
}
export function RemoveUserMember(gid, uid) {
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
        body: "req=delUserMembers&id="+gid+"&userid="+uid
    };  
        
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
}