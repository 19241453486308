import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import Home from './pages/Home';
import HomeNew from './pages/HomeNew';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Cibo from './pages/Cibo';
import AddCibo from './pages/AddCibo';
import Dettagli from './pages/Details';
import Calendario from './pages/Calendario';
import Gallery from './pages/Gallery';
import Chat from './pages/Chat';
import Games from './pages/Games';
import Progetti from './pages/Progetti';
import UserSettings from './pages/UserSettings';
import UserSettingsDetails from './pages/UserSettingsDetails';
import GroupSettings from './pages/GroupSettings';
import GroupSettingsAddons from './pages/GroupSettingsAddons';
import AddGroup from './pages/AddGroup';
import AddOn from './pages/AddSection';
import ShareTarget from './pages/ShareTarget';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Pushy initialization */
import Pushy from 'pushy-sdk-web';
import { PushyMatchUser } from './utils/Pushy';
import { SyncUserSettings } from './utils/Users';

const isAuthed = ("UserName" in localStorage) ? true : false;

if (isAuthed) {
  // Register visitor's browser for push notifications
  const PushyToken = Pushy.register({ appId: '5ee2b9a75fd0a3c709fc80c4' }).then(function (deviceToken) {
    // Print device token to console
    console.log('Pushy device token: ' + deviceToken);

    // Send the token to your backend server via an HTTP GET request
    //fetch('https://your.api.hostname/register/device?token=' + deviceToken);

    // Succeeded, optionally do something to alert the user
    return deviceToken;
  }).catch(function (err) {
    // Handle registration errors
    console.error(err);
  });
  // Check if the device is registered
  if (Pushy.isRegistered()) {
    // Subscribe the device to a topic
    Pushy.subscribe('Cibo').catch(function (err) {
        // Handle subscription errors
        console.error('Subscribe failed:', err);
    });
  }
  console.log(PushyToken + " è il token");

}

if (isAuthed) { PushyMatchUser(); SyncUserSettings(); }

const App: React.FC = () => {
  return (
    <IonApp>
      {isAuthed ? (
            <IonReactRouter>
            <IonSplitPane contentId="main">
              <Menu />
              <IonRouterOutlet id="main">
                <Route path="/" exact={true}>
                  <Redirect to="/page/user/Home" />
                </Route>
                <Route path="/:name" exact={true}>
                  <Redirect to="/page/user/Home" />
                </Route>
                <Route path="/page/:name" exact={true}>
                  <Page />
                </Route>
                <Route path="/page/:gid/Home" exact={true} render={props => <HomeNew key={props.match.params.gid} {...props} />}>
                </Route>
                <Route path="/page/:gid/AddSection" exact={true}>
                  <AddOn />
                </Route>
                <Route path="/page/:gid/Cibo" exact={true}>
                  <Cibo />
                </Route>
                <Route path="/page/:gid/Cibo/add" exact={true}>
                  <AddCibo />
                </Route>
                <Route path="/page/:gid/Cibo/:id/Dettagli" exact={true} render={props => <Dettagli key={props.match.params.id} {...props} />}/>
                <Route path="/page/:gid/Calendario" exact={true} render={props => <Calendario key={props.match.params.gid} {...props} />} />
                <Route path="/page/:gid/Gallery" exact={true} render={props => <Gallery key={props.match.params.gid} {...props} />} />
                <Route path="/page/UserSettings" exact={true}>
                  <UserSettings />  
                </Route> 
                <Switch>
                  {/* Dentro lo Switch regna l'ordine di inserimento */}
                  <Route path="/page/UserSettings/:name" exact={true}>
                    <UserSettingsDetails />  
                  </Route>
                  <Route path="/page/:gid/Chat" exact={true} render={props => <Chat key={props.match.params.gid} {...props}/>}/>
                </Switch>
                <Route path="/page/:gid/GroupSettings" exact={true} render={props => <GroupSettings key={props.match.params.gid} {...props} />}/>
                <Route path="/page/:gid/GroupSettings/Addons" exact={true} render={props => <GroupSettingsAddons key={props.match.params.gid} {...props} />}/>
                <Route path="/page/Group/add" exact={true}>
                  <AddGroup />
                </Route>
                <Route path="/page/:gid/Games" exact={true}>
                  <Games />
                </Route>
                <Route path="/page/:gid/Progetti" exact={true}>
                  <Progetti />
                </Route>
                <Route path="/page/ShareTarget" exact><ShareTarget/></Route>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
          ) : (
            <IonReactRouter>
              <Switch>
                <Route path="/login" component={Login} exact />
                <Route path="/signup" component={SignUp} exact />
                <Route path="*" exact={true}>
                    <Redirect to="/login" />
                </Route>
              </Switch>
            </IonReactRouter>
          )}
      
    </IonApp>
  );
};

export default App;
