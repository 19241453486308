import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonAvatar, IonIcon } from '@ionic/react';
import { logoXbox, logoSteam, logoPlaystation } from 'ionicons/icons';
import './Page.css';

const Games: React.FC = () => {

  const name = "Games";

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem>
              <IonIcon ios={logoXbox} md={logoXbox} />
              <IonLabel>
                <h2>XBOX GAME PASS</h2>
              </IonLabel>
          </IonItem>
          <IonItem>
              <IonIcon ios={logoSteam} md={logoSteam} />
              <IonLabel>
                <h2>Steam/Epic</h2>
              </IonLabel>
          </IonItem>
          <IonItem>
              <IonIcon ios={logoPlaystation} md={logoPlaystation} />
              <IonLabel>
                <h2>Altro</h2>
              </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Games;
