import { IonButtons, IonContent, IonHeader, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonTextarea, IonButton } from '@ionic/react';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { ReadSetting } from '../utils/LocalData';
import './Group.css';

const AddGroup: React.FC = () => {

  const name = "Group";
  const fileInput = useRef(null);
  let history = useHistory();
  const [FileName, setFileName] = useState<string>();
  const onSelectFile = function () {
    document.getElementById("file-btn").hidden = true;
    document.getElementById("file-remove").style.display = "block";
    setFileName(fileInput?.current?.value.split('\\').pop());
  }
  const onRemoveFile = function () {
    document.getElementById("file-remove").style.display = "none";
    document.getElementById("file-btn").hidden = false;
    var inputname = document.getElementById("file-name") as HTMLInputElement;
    var inputfield = document.getElementById("file-input") as HTMLInputElement;
    inputname.value = "";
    inputfield.value = "";
    setFileName(null);
    document.getElementById("file-btn").removeAttribute("disabled");    
  }
  const formSubmit = function (e) {
    SubmitGroup();
    e.preventDefault();
  }

  async function SubmitGroup () {    
    const logoval = fileInput?.current?.files[0];
    const name = (document.getElementById("name-input") as HTMLInputElement).value;
    const desc = (document.getElementById("desc-input") as HTMLInputElement).value;

    const formData = new FormData();
    formData.append('logo', logoval);
    formData.append('name', name);
    formData.append('desc', desc);
    formData.append('user', ReadSetting("UserName"));
    formData.append('req', 'addGroup');


    const requestOptions = {
      method: 'POST',
      body: formData
      //"req=addRestaurant&name="+name+"&desc="+desc+"&longdesc="+ldesc+"&address="+address+"&logo="+logoval
    };    
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => {console.log(res); history.push("/page/"+res+"/Home"); window.location.reload();});
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color='light' />
          </IonButtons>
          <IonTitle color='light'>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader> */}

        <IonList><form id="add-group-form" onSubmit={formSubmit}>
          <IonItem>
            <IonLabel position="fixed">Nome</IonLabel>
            <IonInput id="name-input" required></IonInput>
          </IonItem>
          <IonItem>
            <IonInput placeholder="Breve descrizione" id="desc-input"></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="fixed">Logo</IonLabel>
            <input
              ref={fileInput}
              hidden
              type="file"
              id="file-input"
              accept="image/png, image/jpeg"
              onChange={onSelectFile}
              onClick={() => {
                console.log('onClick');
              }}
            />
            <IonButton
              color="primary"
              id="file-btn"
              onClick={() => {
                // @ts-ignore
                fileInput?.current?.click();
                // setBackgroundOption(BackgroundOptionType.Gradient);
              }}>
              Scegli File
            </IonButton>
            <IonInput value={FileName} id="file-name" readonly ></IonInput>
            <IonButton id="file-remove" onClick={onRemoveFile} style={{display: "none"}} >Rimuovi</IonButton>
          </IonItem>
          <IonButton type="submit" expand="block">Crea</IonButton></form>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default AddGroup;
