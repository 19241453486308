// App Managment Functions

export function UpdateApp() {
    console.log("update");
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            if(registration.waiting){
                registration.waiting.postMessage({type: 'SKIP_WAITING'})
                window.location.reload();
            }            
        });
    }
}
export async function UpdateIsWaiting() {
    if ('serviceWorker' in navigator) {
        console.log("prima await 1");
        var result = await navigator.serviceWorker.ready.then(async (registration) => {
            console.log("2");
            await registration.update();
            console.log("3");
            if(registration.waiting){
                console.log("4");
                return true;
            } else { console.log("7"); return false; }
        });
        console.log(result);
        return result;
    } else { console.log("9"); return "false"; }
}