import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonAvatar, IonImg, IonRefresher, IonRefresherContent } from '@ionic/react';
import './Page.css';
import EvoCalendar from '../components/EvoCalendar';
import { RouteComponentProps } from 'react-router';
import { RefresherEventDetail } from '@ionic/core';
import { useState } from 'react';

interface CalendarPageProps extends RouteComponentProps<{
  [gid: string]: string;
}> {}

const Calendario: React.FC<CalendarPageProps> = ({match}) => {

  const name = "Calendario";
  //const gid = (match.params.gid != undefined) ? match.params.gid : new URL(window.location.href).pathname.split('/')[2];
  const [gid] = useState<any>((match.params.gid != undefined) ? match.params.gid : new URL(window.location.href).pathname.split('/')[2]);
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log('Begin async operation');
    window.location.reload();
  
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <EvoCalendar group={gid}/>
      </IonContent>
    </IonPage>
  );
};

export default Calendario;
