// Group Edit Functions
import { DeleteSetting, WriteSetting } from "./LocalData";
import Compressor from "compressorjs";

export function EditGroupInfo(gid, title, desc) {
    const requestOptions = {
    method: 'POST',
    headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
    body: "req=editGroupSettings&id="+gid+"&title="+title+"&desc="+desc
    };  
    
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
    
}
export function EditGroupImage(gid, logo) {
    new Compressor(logo, {
        quality: 0.8,
        convertSize: 2000000,
    
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {            
            const formData = new FormData();

            // The third parameter is required for server
            // @ts-ignore: La proprietà 'name' non esiste nel tipo 'Blob
            formData.append('logo', result, result.name);
            
            formData.append('id', gid);
            formData.append('req', 'editGroupImage');

            const requestOptions = {
                method: 'POST',
                body: formData
            };    
            fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
        },
        error(err) {
            console.log(err.message);
        },
      });    
}
export function EditGroupOrder(gid, order) {
    const formData = new FormData();
    formData.append('req', 'editGroupOrder');
    formData.append('id', gid);
    formData.append('order', JSON.stringify({ menu : order }));
    const requestOptions = {
        method: 'POST',
        body: formData
    };
    
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
    
}
export function GetGroupHome(gid) {
    const formData = new FormData();
    formData.append('req', 'getGroupHome');
    formData.append('id', gid);    
    const requestOptions = {
        method: 'POST',
        body: formData
    };
    
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => {console.log(res); WriteSetting(gid+"-layouts", res);});    
    
}
export function EditGroupHome(gid, layout) {
    const formData = new FormData();
    formData.append('req', 'editGroupHome');
    formData.append('id', gid);
    formData.append('layouts', JSON.stringify({
        ["layouts"]: layout
      }));
    const requestOptions = {
        method: 'POST',
        body: formData
    };
    
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
    
}
export function AddGroupMember(gid, uid) {
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
        body: "req=addGroupMembers&id="+gid+"&userid="+uid
    };  
        
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
}
export function RemoveGroupMember(gid, uid) {
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
        body: "req=delGroupMembers&id="+gid+"&userid="+uid
    };  
        
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
}
export function RemoveGroupAddon(gid, uid) {
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
        body: "req=delGroupAddon&group="+gid+"&addon="+uid
    };  
        
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
}