import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonAvatar, IonImg, IonButton, IonText, IonIcon, IonFab, IonFabButton, IonAlert, IonPopover } from '@ionic/react';
import { add, addOutline, pencil } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Responsive, WidthProvider} from "react-grid-layout";
import { SizeMe, withSize } from 'react-sizeme'
import './Page.css';
import '../components/react-grid-layout.css';
import '../components/react-resizable.css';
import { ReadSetting, WriteSetting } from '../utils/LocalData';
import { EditGroupHome, GetGroupHome } from '../utils/Groups';
import { RouteComponentProps } from 'react-router';
import { EventWidget, TelegramWidget, WeatherWidget, Widget } from '../components/HomeWidgets';
import * as aas from '../components/HomeWidgets';

interface UserDetailPageProps extends RouteComponentProps<{
  [gid: string]: string;
}> {}

 const bbsbs = aas;
 var oldBP = '';
  var WidgetsStructure = Array();
const Home: React.FC<UserDetailPageProps> = ({match}) => {
  const name = "Home";

  useEffect(() => {
    if(match.params.gid != "user") GetGroupHome(match.params.gid);
  }, [])

  const withSizeHOC = withSize({monitorHeight: true, monitorWidth: true, refreshRate: 60});
  const ResponsiveReactGridLayout = withSizeHOC(Responsive);
  const [bp, setBp] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);
  const [layouts, setLayouts] = useState(LoadLayouts());
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });


  function LoadLayouts(){
    const LoadLayouts = JSON.parse(ReadSetting(match.params.gid+"-layouts") || '{}');
    const originalLayouts = (Object.keys(LoadLayouts).length != 0 && LoadLayouts["layouts"] != undefined) ? LoadLayouts["layouts"] : '{}';
    return JSON.parse(JSON.stringify(originalLayouts));
  }

  function ToggleEdit(){
    if(editing){EditGroupHome(match.params.gid, layouts)};
    if(!editing){aas.SyncWidgets(); WidgetsStructure = JSON.parse(ReadSetting("Widgets"));};
    setEditing(!editing);
  }

  function onLayoutChangeFN(layout, layouts) {
    delete layouts.layouts;
    if(editing) WriteSetting(match.params.gid+"-layouts", JSON.stringify({
      ["layouts"]: layouts
    }));
    if(editing) setLayouts( layouts );
  }
  const [showAlert1, setShowAlert1] = useState(false);

  function DeleteWidget(widget) {
    var tmplayouts = JSON.parse(JSON.stringify(layouts));
    Object.entries(tmplayouts).map((item) => {
      if(item[0] != '0' && item[0] != '1'){
        if(item[1] && Array.isArray(item[1])){
          item[1].map((wdg, index) => {
            if(wdg.i == widget) { 
              tmplayouts[item[0]].splice(index, 1);
            }
        })}
      }
    })
    setLayouts(tmplayouts);
  }

  function AddWidget(widget) {
    var tmplayouts = JSON.parse(JSON.stringify(layouts));
    Object.entries(tmplayouts).map((item) => {
      if(item[0] != '0' && item[0] != '1'){
        if(item[1] && Array.isArray(item[1])){
          const newEntry: Widget = { "i": widget.CodeID, "h": (widget.minH > 0) ? parseInt(widget.minH) : 1, "w": (widget.minW > 0) ? parseInt(widget.minW) : 1, "x": 0, "y": 0, "moved": false, "static": false };
          if (widget.minW > 0) newEntry.minW = parseInt(widget.minW);
          if (widget.minH > 0) newEntry.minH = parseInt(widget.minH);
          if (widget.maxW > 0) newEntry.maxW = parseInt(widget.maxW);
          if (widget.maxH > 0) newEntry.maxH = parseInt(widget.maxH);
          item[1].push(newEntry);
        }
      }
    })
    setLayouts(tmplayouts);
  }

  function RenderWidget(widget) {
    var WidgetName = widget.i;
              if(widget.i == "2" || widget.i == "1" || widget.i == "3") {
                switch (widget.i) {
                  case "1":
                    WidgetName = "TelegramWidget";
                    break;
                  case "2":
                    WidgetName = "EventWidget";
                    break;
                  case "3":
                    WidgetName = "WeatherWidget";
                    break;
                      
                  default:
                    break;
                }
              }
              const WidgetEl = bbsbs[WidgetName];
              return(
                <div key={WidgetName} data-grid={{ w: 5, h: 5, x: 5, y: 0, minW: 2, minH: 5 }}>
                  {(editing) ? <div onClick={() => DeleteWidget(widget.i)}>ELIMINA</div> : null}
                  <WidgetEl gid={match.params.gid}/>
                </div>              
              )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color='light' />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
            <IonIcon icon={pencil} slot='end' onClick={()=>{ToggleEdit()}}></IonIcon>
        </IonToolbar>
      </IonHeader>
<SizeMe>
      {({ size }) => (
      <IonContent fullscreen>
        <IonList id='homepage' className='trasparentBGshadow'>
          
        
          <ResponsiveReactGridLayout
            key={bp} //@ts-ignore
            onBreakpointChange={setBp}
            width={size.width ?? undefined}
            className="layout"
            margin={[0,0]}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm:  6, xs: 4, xxs: 2 }}
            rowHeight={50}
            isDraggable={editing}
            isResizable={editing}
            layouts={layouts}
            onLayoutChange={(layout, layouts) =>
              onLayoutChangeFN(layout, layouts)
            }
          >

          {
            (bp != undefined && bp != "") ? layouts[bp]?.map(widget => {
              oldBP = bp;
              return RenderWidget(widget);              
            }) : (oldBP != '') ? layouts[oldBP]?.map(widget => {
              return RenderWidget(widget);              
            }) : null
          }

          {/* Weather *}
          <div key="WeatherWidget" data-grid={{ w: 5, h: 5, x: 5, y: 0, minW: 2, minH: 5 }}>
            <WeatherWidget/>
          </div>
            {/* Eventi 
            <div key="EventWidget" data-grid={{ w: 5, h: 6, x: 0, y: 0, minW: 2, minH: 5 }}>
              <EventWidget gid={match.params.gid}/>
            </div>
            <div key="TelegramWidget" data-grid={{ w: 20, h: 1, x: 0, y: 0 }}>
              <TelegramWidget/>
            </div>*/}
          </ResponsiveReactGridLayout>
        {/*
          <IonItem className='hm-full'>
              <IonAvatar>
                  <IonImg src={Telegramlogo} alt="Telegram"/>
              </IonAvatar>
              <IonLabel><h1>Telegram</h1></IonLabel>
          </IonItem>
          {/* Eventi *}
          <div className='hm-half'>
            <IonItem className='trasparentBGshadow'>
              <IonLabel><h1>Eventi di oggi</h1></IonLabel>
              <IonIcon icon={arrowBack} slot="start"/>
              <IonIcon icon={arrowForward} slot="end"/>
            </IonItem>
            <IonText><ul>
              {(events == undefined) ? "Nessun evento oggi" : events.map(ev => {
                return(
                <li>{ev.name}</li>
                )
              })
              }</ul>
            </IonText>
          </div>
          
          {/* Weather *}
          <IonItem className='hm-half trasparentBGshadow'>
          <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="it"
            locationLabel="Roma"
            unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
            showForecast={false}
          />
          </IonItem>

            */}
        </IonList>
        {(editing) ?
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={(e) => {/*setShowAlert1(true);*/ setShowPopover({ showPopover: true, event: e });} }>
            <IonIcon ios={addOutline} md={add} />
            <input ref={(el) => { /*setImageInput(el);*/ }} onChange={null /*onSelectImage.bind(this)*/} hidden type="file" id="file-input" accept="image/png, image/jpeg" />
          </IonFabButton>
       </IonFab> : null}
       <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        cssClass="my-custom-class"
        header={'Info'}
        message={'Work in progess.'}
        buttons={['OK']}
      />
      </IonContent>)}
    </SizeMe>
    <IonPopover
        cssClass="my-custom-class"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <IonList>
          {WidgetsStructure.map((widget) => {
            return (
            <IonItem button onClick={() => {AddWidget(widget)}}>{widget.Name}</IonItem>
          )})}
  </IonList>
      </IonPopover>
    </IonPage>
  );
};

export default Home;
