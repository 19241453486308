import { ReadUserSetting } from "./LocalData";

function GetLanguage() {
    return (ReadUserSetting("Language")) ? ReadUserSetting("Language") : Intl.DateTimeFormat().resolvedOptions().locale.split("-")[0];
}

export const GalleryLocalization = () => {

    const Dictionary = {
        it : { "UploadingStatus.Running" : "Caricate", "UploadingStatus.Done" : "Tutte le immagini caricate!" },
        en : { "UploadingStatus.Running" : "Uploaded", "UploadingStatus.Done" : "All images Uploaded!" }  
    };
    const Language = GetLanguage();

    return Dictionary[Language];
}