import React, { useRef, useEffect } from 'react';
import { IonContent, IonImg } from '@ionic/react';
import { ReadSetting } from '../utils/LocalData';
import { useInfiniteQuery, useQuery } from 'react-query';

function Query(props) {
  return props.children(useInfiniteQuery(props.keyName, props.fn, {
    //@ts-ignore
    getNextPageParam: lastPage => (lastMsg == null) ? 0 : '-'+(lastPage[lastPage.length - 1]?.time ?? lastMsg),
    getPreviousPageParam: firstPage => (!firstPage[0]?.last) ? firstPage[0].time : false,

  }));
}
var LoadMoreEl = null;
var loadPreviousPage = null;
var refetchQuery = null;
var loadNextPage = () => null;
var lastMsg = null;
var refetching = false;
var hasPrevs = true;
var blockScroll = false;
var msgDate = null;

function getContent() {
  return document.querySelector('ion-content#chat-content');
}
async function getContentScrollElement(){
  const content = getContent();
  //@ts-ignore
  return await content.getScrollElement();
}
function scrollToPoint(height) {
  const container = getContent();
  //@ts-ignore
  if(container) container.scrollToPoint(0, height);
}
async function scrollToHere() {
  if(!blockScroll){
    const container = getContent();
    const containerScroll = await getContentScrollElement();
    //@ts-ignore
    if(container && containerScroll) container.scrollToPoint(0, (containerScroll.scrollHeight - tempH)+tempST);
  }
}
async function loadPreviousChat(){
  if(!refetching) {
    refetching = true;
    tempH = (await getContentScrollElement()).scrollHeight;
    tempST = (await getContentScrollElement()).scrollTop;
    observer.unobserve(LoadMoreEl);
    loadPreviousPage();
    setTimeout(() => { refetching = false; if(hasPrevs) observer.observe(LoadMoreEl); }, 1200);
    //scrollToPoint(getContent().scrollHeight-tempH);
  }
}
var tempH, tempST = 0;
var observer = new IntersectionObserver(function(entries) {
	// isIntersecting is true when element and viewport are overlapping
	// isIntersecting is false when element and viewport don't overlap
	if(entries[0].isIntersecting === true) {
		//console.log('Element has just become visible in screen');

    loadPreviousChat();
  }
}, { threshold: [1] });
var ChatData = null;
function mergeDataPages(data) {
  var result = [];
  data.pages.map((Page, pindex) => {
    if(Page != "empty") {
    Page.map((value, index) => {
      result.push(value);
    })}
  })
  return result;
}
var messagesEnd = null;
var isAfterFirstFetch = false;

function groupItemsByDate(items) {
  const grouped = {}

  items.forEach(item => {
    //const yearName = moment(item.time, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('YYYY');
    //const monthName = moment(item.time, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MMMM');
    const year = new Date(item.time).getFullYear();
    const month = new Date(item.time).getMonth()+1;
    const day = new Date(item.time).getDate();
    const key = `${('0'+day).slice(-2)}/${('0'+month).slice(-2)}/${year}`;
    
    // if the key does not exists, we create it
    if (!grouped[key]) {
      grouped[key] = [];
    }
    
    grouped[key].push(item);
  })

  return grouped;
}

export default class LoadChat extends React.Component<{ newMessage:boolean, isUpdated, group:string, onImg, definePhotos, viewImg }> {
  constructor(props){
    super(props);
    this.OpenImage = this.OpenImage.bind(this);
  }
  state = { data: [], messagesEnd: null, prevMessage: null as Element, reload: true, refetching: false };

  componentDidMount(){
    isAfterFirstFetch = false;
    tempH = 0;
    setTimeout(() => { isAfterFirstFetch = true; document.getElementById("chat-loading").style.display = "none"; }, 1000);
    const container = getContent();
    //@ts-ignore
    if(container) container.scrollToBottom(0);
    //this.LoadChat();
    //this.ScrollToBottom();
    //setTimeout(this.StartToBottom,200);
    //this.StartToBottom();
    //this.state.chatContent.addEventListener('scroll', this.scrollHandler);
    // useEffect(() => {
          
    //   window.addEventListener('scroll', this.scrollHandler);
  
    //   return () => window.removeEventListener('scroll', this.scrollHandler);
      
    // }, []);
  }

  componentDidUpdate(prevProps){
    if (this.props.newMessage == true) {
      //this.LoadChat();
      //refetchQuery();
      blockScroll = true;
      loadNextPage();
      this.props.isUpdated();
      setTimeout(this.ScrollToBottom,500);
    }
    if ((this.props.viewImg == false && prevProps.viewImg) || (this.props.newMessage == false && prevProps.newMessage)){
      //console.log("carousel is closing");
      setTimeout(() => blockScroll = false,500);
    }

    /*if (this.state.messagesEnd != null && this.state.reload == true) {
      this.setState({reload: false});
      if (this.isScrolledIntoView(this.state.messagesEnd)) {
        console.log("is in view"); 
      } else { console.log("NOT in view");  setTimeout(this.StartToBottom,50); }
    }*/
  }

  ChatFetch = ({ pageParam = 0 }) => {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getChat&group="+this.props.group+"&posix="+pageParam
    };
    return fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json());
  }

  async LoadChat(){    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getChat&group="+this.props.group
    };
    /*fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.json())
    .then(data =>
      this.setState(() => {
        return { data };
      })
    );*/
  }
  StartToBottom = () => {
    const container = getContent();
    //@ts-ignore
    if(container) container.scrollToBottom(0);
    if (messagesEnd != null){
      messagesEnd.scrollIntoView({ behavior: 'instant' });
    }
  }
  ScrollToBottom = () => {
    try {
      messagesEnd.scrollIntoView({ behavior: 'smooth' });  
    } catch (error) {
      console.log(error);
    }    
  }
  isScrolledIntoView(elem)
  {
      var docViewTop = document.querySelector('ion-content.chat').scrollTop;
      var docViewBottom = docViewTop + document.querySelector('ion-content.chat').scrollHeight;

      var elemTop = elem.offsetTop;
      var elemBottom = elemTop + elem.scrollHeight;
      if (docViewTop == 0 && docViewBottom == 0 && elemTop == 0 && elemBottom == 0) {
        setTimeout(() => this.setState({reload: true}), 20);
      }
      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }

  isNearToTop(){
    var docViewTop = document.querySelector('ion-content.chat').scrollTop;
    return (docViewTop <= 100);
  }

  scrollHandler(){
    if (this.isNearToTop) {
      console.log("PREVMESSGEES is in view"); 
    } else { console.log("PREVMESSGEES NOT in view"); }
  }

  OpenImage(event){
    var photos = [];
    var i = 0;
    ChatData.map((msg, index) => {
      const message = JSON.parse(msg.data);
      if(message.hasImage==1 && message.imgs != undefined) {
        photos.push({src: "https://api.vpwi.it/cugust/assets/"+message.imgs, caption: "<b>"+msg.UserName+"</b><br/>"+message.message});
        if (event.currentTarget.src == "https://api.vpwi.it/cugust/assets/"+message.imgs) {
          i = photos.length-1;
        }
      }

    })
    blockScroll = true;
    this.props.definePhotos(photos);
    const img = {src: event.currentTarget.src}
    this.props.onImg(event, {photo: img, index: i});

  }


  render(){
    return(      
      <IonContent class="chat" id='chat-content'>
        <div id="chat-loading"><div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
          </div></div>
      <Query keyName={["chat", this.props.group]} fn={this.ChatFetch}>
      {({ data, isLoading, isFetched, isFetchedAfterMount, error, fetchPreviousPage, hasPreviousPage, isFetchingPreviousPage, fetchNextPage, refetch }) => {
        if (isLoading) return <h1>Loading</h1>;
        if (error) return (<p>{"An error has occurred: "+ error}</p>);
        if(data?.pages[0] === "empty") return <p className='sorry-text'>La chat è vuota, che aspetti a scrivere!</p>
        if ((isFetched||isFetchedAfterMount) && !isAfterFirstFetch) setTimeout(this.StartToBottom,150);
        if ((isFetched||isFetchedAfterMount) && isAfterFirstFetch && !blockScroll) setTimeout(scrollToHere, 150);
        ChatData = mergeDataPages(data);
        //const groupedItems = groupItemsByDate(ChatData);   # DA VALUTARE
        loadNextPage = fetchNextPage;
        refetchQuery = refetch;
        return (
          <div>
            <button ref={(el) => { hasPrevs = hasPreviousPage; if(el != null && hasPreviousPage && !refetching) { LoadMoreEl = el; observer.observe(LoadMoreEl); loadPreviousPage = fetchPreviousPage; } else if (el != null )  { observer.unobserve(el) } }}
              onClick={() => loadPreviousChat()}
              disabled={!hasPreviousPage || isFetchingPreviousPage}
            >
              {isFetchingPreviousPage
                ? 'Loading more...'
                : hasPreviousPage
                ? 'Load Older'
                : 'Nothing more to load'}
            </button>
          {(data?.pages !== undefined && data.pages.length > 0) ? data.pages.map((Page, pindex) => {
            const isLastPage = (data.pages.length === pindex+1) ? true : false;
            if(Page == "empty") return;
            return Page.map((Chat, index) => {
            const message = JSON.parse(Chat.data);
            const sender = Chat.UserName;
            const userid = ReadSetting("UserName");
            const date = new Date(Chat.time);
            const DateGroupFormat = date.getDate()+'/'+(('0'+(date.getMonth()+1)).slice(-2))+'/'+date.getFullYear();
            var writeDate = false;
            if (msgDate == null || msgDate != DateGroupFormat) {
              msgDate = DateGroupFormat;
              writeDate = true;
            }
            if(index === Page.length-1 && isLastPage) lastMsg = Chat.time;
              return (<>
              {(writeDate)?<div className='dark chat-list-separator'><p>{DateGroupFormat}</p></div>:null}
                <div key={'c'+Chat.id} style={{ clear: "both", backgroundColor: ((sender==userid) ? Chat.Color : null) }} className={"chat-list "+((sender==userid) ? 'mio' : 'tuo')+((message.hasImage==1 && message.imgs != undefined) ? ' chat-image':'')+((message.message == '')?' no-text':'')} >
                  {(sender!=userid) ? <p className="chat-sender" style={{color: Chat.Color}} >{sender}</p> : null}
                  {(message.hasImage==1 && message.imgs != undefined) ? <img src={"https://api.vpwi.it/cugust/assets/"+message.imgs} onClick={this.OpenImage}  alt="" /> :null}
                  <p className='text'><pre>{message.message}</pre></p>
                  <i>{('0'+date.getHours()).slice(-2)+':'+('0'+date.getMinutes()).slice(-2)}</i>
                </div></>
              );
        })} ) :
         <p className='sorry-text'>La chat è vuota, che aspetti a scrivere!</p>
      }<div style={{clear: 'both'}} ref={(el) => { messagesEnd = el; }}></div></div>  );
      }}
      </Query>
        {/*this.state.data.map((Chat, index) => {
        const message = JSON.parse(Chat[0]);
        const sender = Chat[2];
        const userid = ReadSetting("UserName");
        const lastEl = this.state.data.length;
        if(index === lastEl -1){
          return (            
            <div key={Chat[1]} style={{ clear: "both" }} className={"dark "+((sender==userid) ? 'mio' : 'tuo')+((message.hasImage==1) ? ' chat-image':'')+((message.message == '')?' no-text':'')} id="chat-list" ref={(el) => { this.state.messagesEnd = el; }}>
              {(sender!=userid) ? <p className="chat-sender">{sender}</p> : null}
              {(message.hasImage==1) ? <IonImg src={"https://api.vpwi.it/cugust/assets/"+message.imgs} onClick={this.OpenImage}  alt="" /> :null}
              <p className='text'><pre>{message.message}</pre></p>
            </div>
          );
        } else {
        return (
            <div key={Chat[1]} className={"dark "+((sender==userid) ? 'mio' : 'tuo')+((message.hasImage==1) ? ' chat-image':'')+((message.message == '')?' no-text':'')} id="chat-list">
              {(sender!=userid) ? <p className="chat-sender">{sender}</p> : null}
              {(message.hasImage==1) ? <IonImg src={"https://api.vpwi.it/cugust/assets/"+message.imgs} onClick={this.OpenImage} alt="" /> :null}
              <p className='text'><pre>{message.message}</pre></p>
            </div>
      ); }
    })*/} </IonContent>
    )
  }
}