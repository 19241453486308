// Auth User Functions
import { useHistory } from "react-router";
import { DeleteSetting, WriteSetting } from "./LocalData";
import { deletePushyMatchUser } from "./Pushy";

export function LogInUser(history, username, email) {
    WriteSetting("UserName",username);
    WriteSetting("Email", email);
    /*history.replace("/");
    window.location.reload();*/
    window.location.href = "/";
}
export function LogOutUser() {
    deletePushyMatchUser();
    DeleteSetting("UserName");
    DeleteSetting("Email");
    //history.replace("/");
    window.location.href = "/";
}