import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import React from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { LoadUserSettings } from '../components/loadSettings';
import './User.css';

// interface UserDetailPageProps extends RouteComponentProps<{
//   [id: string]: string;
// }> {}

const UserSettings: React.FC = () => {
  const name = "UserSettings";
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log('Begin async operation');
    window.location.reload();
  
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color='light' />
          </IonButtons>
          <IonTitle color='light'>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
        {/* <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <LoadUserSettings/>  
      </IonContent>
    </IonPage>
  );
};

export default UserSettings;
