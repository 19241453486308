import { IonButton, IonButtons, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonInput, IonMenuButton, IonPage, IonRow, IonSplitPane, IonTitle, IonToolbar, IonMenu, IonNote } from '@ionic/react';
import { useSignupFields } from '../utils/Fields';
import { validateForm } from '../utils/Form';
import CustomField from '../components/CustomField';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import './Login.css';
import { LogInUser } from '../utils/Auth';

const SignUp: React.FC = () => {

  const name = "SignUp";

    const params = useParams();
    const fields = useSignupFields();
    const [ errors, setErrors ] = useState<any>(false);
    //const [ result, setResult ] = useState<any>(false);
    var result;
    let history = useHistory();

    const login = () => {

        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {

            //  Submit your form here 
            const name = (document.getElementById("name") as HTMLInputElement).value;
            const email = (document.getElementById("email") as HTMLInputElement).value;
            const password = (document.getElementById("password") as HTMLInputElement).value;
            const formData = new FormData();
            formData.append('username', name);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('req', 'NewUser');
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.text())
            .then(data => result = data
                //setResult(data)
            ).then(() => {
                if (result == "OK") {
                    LogInUser(history, name, email);
                } else { alert(result); }
            });
            
        }
    }

    useEffect(() => {

      return () => {

          fields.forEach(field => field.input.state.reset(""));
          setErrors(false);
      }
  }, [params]);

  return (
    <IonPage>
        <IonSplitPane contentId="signup">
            <IonMenu contentId="signup">
                <img className="full-width" src="https://images.unsplash.com/photo-1567360425618-1594206637d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2160&q=80"/>
            </IonMenu>
            <IonContent id="signup" fullscreen>
                <IonGrid className="ion-padding">
                    <IonRow>
                        <IonCol size="12" className="headingText">
                            <IonCardTitle>Sign Up</IonCardTitle>
                            <h5>Benvenuto!</h5>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol size="12">
                        <form onSubmit={ login }>

                            { fields.map(field => {

                                return <CustomField field={ field } errors={ errors } />;
                            })}

                            <IonButton className="custom-button" expand="block" onClick={ login }>Sign Up</IonButton>
                            <input type="submit" hidden />
                        </form>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <IonNote>Sei già registrato, <a href="/login">Accedi!</a></IonNote>
                        </IonCol>
                    </IonRow>
                </IonGrid>
			</IonContent>
        </IonSplitPane>
    </IonPage>
  );
};

export default SignUp;
