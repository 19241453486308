import { IonInput, IonLabel } from "@ionic/react";
import '../pages/Login.css';

const CustomField = ({field, errors}) => {

    const error = errors && errors.filter(e => e.id === field.id)[0];
    const errorMessage = error && errors.filter(e => e.id === field.id)[0].message;

    return (
        
        <div className="field">
            <IonLabel className="fieldLabel">
                { field.label }
                { error && <p className="animate__animated animate__bounceIn">{ errorMessage }</p> }
            </IonLabel>
            <IonInput className="customInput" id={field.id} { ...field.input.props } { ...field.input.state } />
        </div>
    );
};

export default CustomField;