import React from 'react';
import { IonContent, IonAlert, IonButton, IonTitle, IonText, IonImg } from '@ionic/react';

export default class LoadDettagli extends React.Component<{ id:string, group:string }> {
  state = { data: [], showAlert1: false };

  componentDidMount(){
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getDetails&id="+this.props.id
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.json())
    .then(data =>
      this.setState(() => {
        return { data };
      })
    );
  }

  addEvent(){
    const date = (document.getElementById("proposeDate") as HTMLInputElement).value;
    const event = new FormData();
      event.append('req', "addEvent");
      event.append('name', this.state.data[0].name);
      event.append('data', date);
      event.append('location', this.props.id);
      event.append('type', "event");
      event.append('everyYear', '0');
      //TO-FIX
      event.append('group', this.props.group);
    const requestOptions = {
      method: 'POST',
      body: event
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));
    //$.post( "https://api.vpwi.it/cugust/apisql.php", { req: "addEvent", name: nome, data: $scope.data.date, location: id, type: "event", everyYear: 0 }
  }

  render(){
    const oggi = new Date();
    const oggitext = oggi.getFullYear()+'-'+(oggi.getMonth().toString().length == 1 ? '0'+(oggi.getMonth()+1) : (oggi.getMonth()+1))+'-'+(oggi.getDate().toString().length == 1 ? '0'+oggi.getDate() : oggi.getDate());    
    return(
      <IonContent class="details" key="1">{this.state.data.map(Detail => {
        return (
          <div key="2">
            <IonImg src={"data:image/jpeg;base64,"+Detail.logo} alt={Detail.name}/>
            <IonTitle>{Detail.name}</IonTitle><br/>
            <IonText>{Detail.description}</IonText><br/>
            <IonButton onClick={() => this.setState({ showAlert1: true})} expand="block">Proponi</IonButton>
            <IonAlert
              isOpen={this.state.showAlert1}
              onDidDismiss={() => this.setState({ showAlert1: false})}
              cssClass='my-custom-class'
              header={'Scegli un giorno'}
              message={'in cui proporre <strong>'+Detail.name+'</strong>'}
              inputs={[
                {
                  name: 'Data',
                  placeholder: 'Seleziona Data',
                  type:'date',
                  id:'proposeDate',
                  min:oggitext
                }
              ]}
              buttons={[
                {
                  text: 'Annulla',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: blah => {
                    console.log('Confirm Cancel: blah');
                  }
                },
                {
                  text: 'Proponi',
                  handler: () => {
                    this.addEvent();
                    console.log('Event Added');
                  }
                }
              ]}
            />
          </div>
      );
    })} </IonContent>
    )
  }
} 