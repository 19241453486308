import React from 'react';
import { IonList, IonItem, IonLabel, IonAvatar, IonImg } from '@ionic/react';

export default class LoadCibo extends React.Component {
  state = { data: [] };

  componentDidMount(){
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getCibo"
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.json())
    .then(data =>
      this.setState(() => {
        return { data };
      })
    );
  }

  render(){
    
    return(
      <IonList class="cibo">{this.state.data.map(el => (
        <IonItem id={el.id} key={el.id} routerLink={"Cibo/"+el.id+"/Dettagli/"} routerDirection="forward" detail>
              <IonAvatar>
                  <IonImg src={"data:image/jpeg;base64,"+el.logo} alt="Tostiamo"/>
              </IonAvatar>
              <IonLabel>
                <h2>{el.name}</h2>
                <p>{el.desc}</p>
              </IonLabel>
          </IonItem>
      ))}</IonList>
    )
  }
} 