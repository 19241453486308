import { IonAlert, IonButton, IonButtons, IonCardHeader, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonModal, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useLocation, useParams } from 'react-router';
//import { images, imagesOutline, bookmarkOutline, codeWorkingOutline, codeWorking, homeOutline, home, chatboxOutline, chatbox, gameControllerOutline, gameController, fastFoodOutline, fastFood, calendar, calendarOutline, logOutOutline, settingsOutline, add, construct } from 'ionicons/icons';
import {useQuery,useMutation,useQueryClient,QueryClient,QueryClientProvider} from 'react-query'
import './Section.css';
import { useState } from 'react';

const AddOn: React.FC = () => {

  const name = "Aggiungi sezione";
  const [showModal, setShowModal] = useState(false);
  const [ActiveAddon, setActiveAddon] = useState({Name: null, Description: null, Icon: null, Installed: false, id: null});
  const [showAlert1, setShowAlert1] = useState(false);

  const location = useLocation();
  const gid = parseInt(location.pathname.split('/')[2]);    

  const queryClient = new QueryClient();
  const icons = require('ionicons/icons');
  function LoadAddOns(){
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getAddons&group="+gid
    };  

    const { status, data, error, isFetching, isLoading } = useQuery("groups", () => 
      fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json())
    );
    if (isLoading) return (<IonLabel>Loading...</IonLabel>);

    if (error) return (<IonLabel>{"An error has occurred: "+ error}</IonLabel>);

    return (
      <div id='section-container'>
      {(data.length > 0) ? data.map((addon) => (
        <article className='section' onClick={() => { setShowModal(true); setActiveAddon(addon); }}>
          <div className='section-bg' style={{backgroundColor: addon.Color}}></div>
          <IonIcon ios={eval("icons."+addon.Icon)} md={eval("icons."+addon.Icon)} />
          <h4>{addon.Name}</h4>
          <p>{addon.Slogan}</p>
          {(addon.Installed) ? <p className='installed'>- Installato</p> : null}
        </article>
      )) : <IonItem lines="none"><IonLabel>Stanamente non ci sono AddOn! Riprova a tornare più tardi.</IonLabel></IonItem> }
      </div>
    );
  }

  function addAddon(){
    const event = new FormData();
      event.append('req', "addGroupAddon");
      event.append('addon', ActiveAddon.id);
      event.append('group', ''+gid);
    const requestOptions = {
      method: 'POST',
      body: event
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => {console.log(res); setShowModal(false); window.location.reload();});
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color='light' />
          </IonButtons>
          <IonTitle color='light'>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader> */}
          <QueryClientProvider client={queryClient}>
            <LoadAddOns />
          </QueryClientProvider>
          <IonModal
            isOpen={showModal}
            cssClass='addon-details'
            swipeToClose={true}
            presentingElement={ undefined}
            onDidDismiss={() => setShowModal(false)}>
              <IonCardHeader>
                <IonTitle><IonIcon ios={eval("icons."+ActiveAddon.Icon)} md={eval("icons."+ActiveAddon.Icon)} />{ActiveAddon.Name}</IonTitle>
                {(ActiveAddon.Installed)? <IonButton disabled={true}>Installato</IonButton> : <IonButton onClick={() => setShowAlert1(true)} disabled={false}>Aggiungi</IonButton>}
            </IonCardHeader>
            <hr/>
            <p>{ActiveAddon.Description}</p>
            <IonButton className='modal-close' onClick={() => setShowModal(false)}>Chiudi</IonButton>
          </IonModal>
          <IonAlert
            isOpen={showAlert1}
            onDidDismiss={() => setShowAlert1(false)}
            cssClass="my-custom-class"
            header={'Aggiungere '+ActiveAddon.Name+'?'}
            message={'Procedendo si autorizzerà l\'addon ad accedere al gruppo!'}
            buttons={[
              {
                text: 'Annulla',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                  console.log('Confirm Cancel: blah');
                },
              },
              {
                text: 'Aggiungi',
                handler: () => {
                  console.log('Confirm Okay');
                  addAddon();
                },
              },
            ]}
          />
      </IonContent>
    </IonPage>
  );
};

export default AddOn;
