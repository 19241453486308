import React from 'react';
import $ from 'jquery';
import "../components/evo-calendar.js";

export default class EvoCalendar extends React.Component<{ group:string }> {
  componentDidMount(){
    this.initEvoCalendar();
  }

  async initEvoCalendar(){
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getEvents&group="+this.props.group
    };
    // fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json())
    // .then(data => (data.length > 0) ? 
    // $('#calendar').evoCalendar('addCalendarEvent', data) : null
    // );
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json())
    .then(data => {
      const calendar = $('#calendar');
      var events = (data.length > 0) ? data : [];
      events.push({
          id: "000", // Event's id (required, for removing event)
          name: "Today's Event", // Name of event
          date: new Date(), // Date of event
          type: "holiday", // Type of event (event|holiday|birthday)
          everyYear: true // Event is every year (optional)
        });
      if(calendar.length > 1){ calendar.evoCalendar('destroy'); }
      calendar.evoCalendar({
        theme: 'Dark Red',
        //theme: 'Midnight Blue',
        language: 'it',
        firstDayOfWeek: 1,
        'calendarEvents': events }) 
      });
  }

  OLD_initEvoCalendar(){
    $('#calendar').evoCalendar({
      theme: 'Dark Red',
      //theme: 'Midnight Blue',
      language: 'it',
      firstDayOfWeek: 1,
      'calendarEvents': [
        {
          id: "000", // Event's id (required, for removing event)
          name: "Today's Event", // Name of event
          date: new Date(), // Date of event
          type: "holiday", // Type of event (event|holiday|birthday)
          everyYear: true // Event is every year (optional)
        }]
    })
  }
  render(){
    return(
      <div id="calendar"></div>
    )
  }
} 