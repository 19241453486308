import { IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams, } from 'react-router';
import { ReadSetting } from '../utils/LocalData';
import {useQuery,useMutation,useQueryClient,QueryClient,QueryClientProvider} from 'react-query'
import './ShareTarget.css';
import { peopleOutline } from 'ionicons/icons';

const ShareTarget: React.FC = () => {

  const name = "ShareTarget";

  const [IMG, setImg] = useState<string>('');  
  // navigator.serviceWorker.addEventListener('message', function handler (event) {
  //   console.log(event.data.name);
  // });
  useEffect(() => {
    async function LoadImage() {
      const parsedUrl = new URL(window.location.href);
      const src = parsedUrl.searchParams.get('url');
      if (IMG == '' && (src != undefined && src != "")){
        const cache = await caches.open('media');
        const response = await cache.match(src);
        const blob = await response.blob();
        const file = new File([blob], src, {
          type: response.headers.get('content-type'),
        });
        const url = URL.createObjectURL(file);
        setImg(url);
      }
    }
    
    LoadImage();    
  })

  const queryClient = new QueryClient();
  function LoadDestinations(){
    const parsedUrl = new URL(window.location.href);
    const src = parsedUrl.searchParams.get('url');
    var param = '';
    if (src != undefined && src != ""){
      param = '?url='+src;
    }

    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getGroups&user="+ReadSetting("UserName")
    };  

    const queryClient = useQueryClient();
    const { status, data, error, isFetching, isLoading } = useQuery("groups", () => 
      fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json())
    );
    if (isLoading) return (<IonLabel>Loading...</IonLabel>);

    if (error) return (<IonLabel>{"An error has occurred: "+ error}</IonLabel>);

    return (
      <div>
      {(data.length > 0) ? data.map((group) => (
        <div onClick={() => window.location.href = '/page/'+group.id+'/Chat/'+param} className={"dests"} style={{ background: `url(${ ( group.image !== null) ? "https://api.vpwi.it/cugust/assets/img/group-imgs/"+group.image : "/assets/img/bg.png"})`, backgroundRepeat: `${(group.image !== null) ? 'no-repeat' : 'repeat'}`, backgroundSize: `${(group.image !== null) ? 'cover' : 'auto'}` }}>
        <IonItem lines="none" className="trasparentBGshadow" key={'g'+group.id} > {/*routerLink={"/page/Chat/"+group.id}*/}
          <IonIcon slot="start" icon={peopleOutline} />
          <IonLabel><b>{group.GroupName}</b></IonLabel>
        </IonItem>
      </div>
      )) : <IonItem lines="none"><IonLabel>Non fai ancora parte di nessun gruppo!</IonLabel></IonItem> }
      </div>
    );
  }
  
  function Share() {
    navigator.share({ title: "hello world", text: "ciao mondo!"});
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color='light' />
          </IonButtons>
          <IonTitle color='light'>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <img src={IMG} onClick={Share}/>
        <QueryClientProvider client={queryClient}>
          <LoadDestinations/>
        </QueryClientProvider>
      </IonContent>
    </IonPage>
  );
};

export default ShareTarget;
