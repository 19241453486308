// LocalStorage/Settings Functions
const localdata = window.localStorage;

export function WriteSetting(settingName, settingValue) {
    localdata.setItem(settingName, settingValue);
}

export function ReadSetting(settingName) {
    try {
        var value = localdata.getItem(settingName);
    } catch (e) {
        var value = '';
    }
    return value;
}

export function ReadUserSetting(settingName) {
    var value = JSON.parse(ReadSetting("UserSettings"))[settingName];
    return value;
}

export function DeleteSetting(settingName) {
    localdata.removeItem(settingName);
}