import React from 'react';
import { IonContent, IonAlert, IonButton, IonTitle, IonText, IonImg, IonLabel, IonIcon, IonInput, IonTextarea, IonFab, IonFabButton, IonFabList, IonList, IonListHeader, IonItem, IonToggle } from '@ionic/react';
import { add, checkmark, close, closeOutline, image, imageOutline, logOutOutline, pencil, pencilOutline, chatbubbles, trash, trashOutline, reloadOutline, colorPalette } from 'ionicons/icons';
import { AddGroupMember, EditGroupImage, EditGroupInfo, RemoveGroupAddon, RemoveGroupMember } from '../utils/Groups';
import { EditUserSetting, EditUserImage, EditUserInfo, RemoveUserMember, EditUserColor } from '../utils/Users';
import { ReadSetting, ReadUserSetting } from "../utils/LocalData";
import { LogOutUser } from '../utils/Auth';
import { useQuery } from 'react-query';
import { UpdateApp, UpdateIsWaiting } from '../utils/Application';
import { CirclePicker } from 'react-color';

export class LoadGroupSettings extends React.Component<{ id:string }> {
  state = { data: [], members: [], showAlert1: false, showAlert2: false, edit: false, title: null, description: null, bgImage: null, imageInput: null, selectedUser: null };

  componentDidMount(){
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getGroupSettings&id="+this.props.id
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.json())
    .then(data =>
      this.setState({data : data, title: data[0].GroupName, description: data[0].description, bgImage: data[0].image})
    );

    const requestOptions2 = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getGroupMembers&id="+this.props.id
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions2).then(response => response.json())
    .then(members =>
      this.setState(() => {
        return { members };
      })
    );
  }

  onImage() {
    this.state.imageInput?.click();
  }
  onSelectImage(event) {    
    this.setState({
      bgImage: URL.createObjectURL(event.target.files[0])
    })
    //document.getElementById("img-btn").style.display = "none";
    //document.getElementById("prev-img").style.display = "block";
  }

  submitChanges(){
    const title = (document.getElementById("group-title") as HTMLInputElement).value;
    const desc = (document.getElementById("group-desc") as HTMLInputElement).value;
    if(title!=this.state.data[0].GroupName || desc != this.state.data[0].description) {
      EditGroupInfo(this.props.id, title, desc);
      this.setState({title: title, description: desc})
    }
    if (this.state.data[0].image !== this.state.bgImage) {
      const image = this.state.imageInput.files[0];
      EditGroupImage(this.props.id, image);
    }
    this.setState({edit: !this.state.edit});
  }

  imageSRC(url){
    var value;
    if (url !== this.state.bgImage) {
      value = 'url('+this.state.bgImage+ ')';
    } else value = 'url('+ (( url !== null) ? "https://api.vpwi.it/cugust/assets/img/group-imgs/"+url : "/assets/img/bg.png" )+ ')';
    return value;
  }

  addMember(){
    const userID = (document.getElementById("userID") as HTMLInputElement).value.trim();
    if(userID !== null && userID !== "") AddGroupMember(this.props.id, userID);
    window.location.reload();
  }

  delMember(userID){
    RemoveGroupMember(this.props.id, userID);
    window.location.reload();
  }

  render(){
    const hrstyle: React.CSSProperties = {backgroundColor: 'white', marginTop: 0};
    return(
      <IonContent class="settings" key="main-settings">
        {this.state.data.map(Settings => {
          const bgRepeat = ( Settings.image !== null) ? 'no-repeat' : 'repeat';
          const bgSize = ( Settings.image !== null) ? 'cover' : 'auto';
          const bgImg = this.imageSRC(Settings.image);
          const bgStyle: React.CSSProperties = {backgroundImage: bgImg, backgroundRepeat: bgRepeat, backgroundSize: bgSize };
          return (
            <span>
              <div key="first-settings" style={bgStyle}>
                {this.state.edit ? <IonInput className="group-edit" id="group-title" value={this.state.title}></IonInput> : <IonTitle>{this.state.title}</IonTitle>}
                {/*<IonText>{Settings.description}</IonText>*/}
                <IonFab edge vertical="bottom" horizontal="end" slot="fixed">
                  <IonFabButton onClick={() => this.setState({edit: !this.state.edit, title: this.state.data[0].GroupName, description: this.state.data[0].description, bgImage: this.state.data[0].image}) }>
                    <IonIcon ios={pencilOutline} md={pencil} />
                  </IonFabButton>
                  <IonFabList side="top" style={{ pointerEvents: 'none'}}>
                    <IonFabButton className="bgGreen" style={{ pointerEvents: 'all'}} onClick={this.submitChanges.bind(this)}><IonIcon icon={checkmark}/></IonFabButton>
                    <IonFabButton style={{ pointerEvents: 'all'}} onClick={this.onImage.bind(this)} id="img-btn"><IonIcon icon={imageOutline}/><input ref={(el) => { this.state.imageInput = el; }} onChange={this.onSelectImage.bind(this)} hidden type="file" id="file-input" accept="image/png, image/jpeg" /></IonFabButton>
                  </IonFabList>
                </IonFab>               
              </div>
              <hr style={hrstyle}/>
              {this.state.edit ? <IonTextarea className="group-edit" id="group-desc" value={this.state.description} placeholder="Descrizione (opzionale)"></IonTextarea> :
              <h3>{(Settings.description !== null && Settings.description !== "") ? this.state.description : "Nessuna descrizione"}</h3>}
            </span>
          );
        })} 
    <hr style={hrstyle}/>
    <IonItem className="trasparentBGshadow setting-item" detail routerLink={("/page/:id/GroupSettings/Addons").replace(":id", this.props.id)} routerDirection="forward" ><b>Addons</b></IonItem>


    {/* Membri */}
    <IonList id="labels-list" className="trasparentBGshadow">
          <IonListHeader>
          <IonLabel id="members-label">Membri <span>{this.state.members.length}</span></IonLabel>
            <IonButton color="dark" className="add-button" onClick={() => this.setState({ showAlert1: true})}>
              <IonIcon slot="icon-only" ios={add} md={add} />
            </IonButton>
            <IonAlert
              isOpen={this.state.showAlert1}
              onDidDismiss={() => this.setState({ showAlert1: false})}
              cssClass='my-custom-class'
              header={'Aggiungi un membro'}
              message={'digita l\'username o la e-mail dell\'utente'}
              inputs={[
                {
                  name: 'Utente',
                  placeholder: 'username o e-mail',
                  type:'text',
                  id:'userID',
                  attributes: {autocomplete:'off'},
                  handler: () => {
                    console.log("handtest");
                  }
                }
              ]}
              buttons={[
                {
                  text: 'Annulla',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: blah => {
                    console.log('Confirm Cancel: blah');
                  }
                },
                {
                  text: 'Aggiungi',
                  handler: () => {
                    console.log('Confirm Okay');
                    this.addMember();
                  }
                }
              ]}
            />
          </IonListHeader>
    {/*<h3 id="members-label">Membri <span>{this.state.members.length}</span></h3><IonButton color="dark" className="add-button" routerLink="/page/Group/Add">
              <IonIcon slot="icon-only" ios={add} md={add} />
      </IonButton>*/}
        {this.state.members.map((Member, index) => {
          return (            
              <IonItem key={'m'+index} className="trasparentBGshadow members" onClick={() => this.setState({ showAlert2: true, selectedUser: Member.UserName})}>{Member.UserName}</IonItem>
          );
        })}
        <IonAlert
              isOpen={this.state.showAlert2}
              onDidDismiss={() => this.setState({ showAlert2: false})}
              cssClass='my-custom-class'
              message={'Rimuovere '+this.state.selectedUser+' dal gruppo '+this.state.title+'?'}
              buttons={[
                {
                  text: 'Annulla',
                  role: 'cancel',
                  cssClass: 'secondary'
                },
                {
                  text: 'OK',
                  handler: () => {
                    console.log('Confirm Okay');
                    this.delMember(this.state.selectedUser);
                  }
                }
              ]}
            />
      </IonList>
    </IonContent>
    )
  }
}

function Query(props) {
  return props.children(useQuery(props.keyName, props.fn, props.options));
}
const icons = require('ionicons/icons');

export class LoadGroupSettingsAddons extends React.Component<{ id: string }> {
  state = { showAlert: false, selectedAddon: {Name: null, id: null}};

  gid = this.props.id;

  componentDidMount(){
    
  }


  requestOptions = {
    method: 'POST',
    headers: new Headers({
               'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
    body: "req=getGroupAddons&group="+this.gid
  };

  delAddon(addonID){
    RemoveGroupAddon(this.gid, addonID);
    window.location.reload();
  }

  render(){
    const hrstyle: React.CSSProperties = {backgroundColor: 'white', marginTop: 0};
    return(
      <IonContent class="settings" key="addon-settings">        
        <IonList id="labels-list" className="trasparentBGshadow">
            <IonListHeader>
            <IonLabel id="prefs-label">Addons installati</IonLabel>
            </IonListHeader>
            <Query keyName={["addons", this.gid]} fn={() => fetch('https://api.vpwi.it/cugust/apisql.php', this.requestOptions).then((res) => res.json())}>
              {({ data, isLoading }) => {
                if (isLoading) return <h1>Loading</h1>;
                return (
                  (data !== undefined && data.length > 0) ? data.map((Addon, index) => {
                    return (
                        <IonItem key={'aps'+Addon.id+index} className='trasparentBGshadow prefs'>
                          <IonIcon slot="start" ios={eval("icons."+Addon.Icon)} md={eval("icons."+Addon.Icon)} />
                          <IonLabel>{Addon.Name}</IonLabel>
                          <IonIcon slot="end" ios={trashOutline} md={trash} className="red" onClick={() => this.setState({ showAlert: true, selectedAddon: Addon})} />
                        </IonItem>
                    );
                  }) : <IonLabel className='sorry-text'>Non hai ancora aggiunto alcun addon. Vai nella sezione "+ Aggiungi" per arricchire le funzionalità del tuo gruppo.</IonLabel>
                );
              }}
            </Query>       
        </IonList>
        <IonAlert
              isOpen={this.state.showAlert}
              onDidDismiss={() => this.setState({ showAlert: false})}
              cssClass='my-custom-class'
              header={'Rimuovere '+this.state.selectedAddon.Name+'?'}
              message={'Rimuovendo l\'addond, verranno eliminati anche tutti i relativi dati.'}
              buttons={[
                {
                  text: 'Annulla',
                  role: 'cancel',
                  cssClass: 'secondary'
                },
                {
                  text: 'Rimuovi',
                  handler: () => {
                    console.log('Confirm Okay');
                    this.delAddon(this.state.selectedAddon.id);
                  }
                }
              ]}
            />
    </IonContent>
    )
  }
}

export class LoadUserSettings extends React.Component {
  state = { data: [], edit: false, title: null, Email: null, Bio: null, bgImage: null, imageInput: null, update : false };

  SettingsCategories = [
    {
      icon : chatbubbles,
      name : "Chat"
    }
  ];

  async componentDidMount(){
    const Username = ReadSetting("UserName");
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getUserDetails&id="+Username
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.json())
    .then(data =>
      this.setState({data : data, title: data[0].UserName, Email: data[0].Email, Bio: data[0].Bio, bgImage: data[0].image})
    );
    this.setState({update: await UpdateIsWaiting()})
  }

  onImage() {
    this.state.imageInput?.click();
  }
  onSelectImage(event) {    
    this.setState({
      bgImage: URL.createObjectURL(event.target.files[0])
    })
  }

  submitChanges(){
    const Username = ReadSetting("UserName");
    const title = (document.getElementById("user-title") as HTMLInputElement).value;
    const desc = (document.getElementById("user-desc") as HTMLInputElement).value;
    if(title!=this.state.data[0].UserName || desc != this.state.data[0].Bio) {
      EditUserInfo(Username, title, desc);
      this.setState({title: title, Bio: desc})
    }
    if (this.state.data[0].image !== this.state.bgImage) {
      const image = this.state.imageInput.files[0];
      EditUserImage(Username, image);
    }
    this.setState({edit: !this.state.edit});
  }

  imageSRC(url){
    var value;
    if (url !== this.state.bgImage) {
      value = 'url('+this.state.bgImage+ ')';
    } else value = 'url('+ (( url !== null) ? "https://api.vpwi.it/cugust/assets/img/user-imgs/"+url : "/assets/img/bg.png" )+ ')';
    return value;
  }

  async checkUpdates(){
    //this.forceUpdate();
    const IsWaiting =  await UpdateIsWaiting();
    this.setState({update: IsWaiting});
  }

  render(){
    const hrstyle: React.CSSProperties = {backgroundColor: 'var(--ion-color-dark)', marginTop: 0};
    return(
      <IonContent class="settings" key="main-settings">
        {this.state.data.map(Settings => {
          const bgRepeat = ( Settings.image !== null) ? 'no-repeat' : 'repeat';
          const bgSize = ( Settings.image !== null) ? 'cover' : 'auto';
          const bgImg = this.imageSRC(Settings.image);
          const bgStyle: React.CSSProperties = {backgroundImage: bgImg, backgroundRepeat: bgRepeat, backgroundSize: bgSize };
          return (
            <span>
              <div key="first-settings" style={bgStyle}>
                {this.state.edit ? <IonInput className="user-edit" id="user-title" value={this.state.title}></IonInput> : <IonTitle>{this.state.title}</IonTitle>}
                {/*<IonText>{Settings.Bio}</IonText>*/}
                <IonFab edge vertical="bottom" horizontal="end" slot="fixed">
                  <IonFabButton onClick={() => this.setState({edit: !this.state.edit, title: this.state.data[0].UserName, Bio: this.state.data[0].Bio, bgImage: this.state.data[0].image}) }>
                    <IonIcon ios={pencilOutline} md={pencil} />
                  </IonFabButton>
                  <IonFabList side="top" style={{ pointerEvents: 'none'}}>
                    <IonFabButton className="bgGreen" style={{ pointerEvents: 'all'}} onClick={this.submitChanges.bind(this)}><IonIcon icon={checkmark}/></IonFabButton>
                    <IonFabButton style={{ pointerEvents: 'all'}} onClick={this.onImage.bind(this)} id="img-btn"><IonIcon icon={imageOutline}/><input ref={(el) => { this.state.imageInput = el; }} onChange={this.onSelectImage.bind(this)} hidden type="file" id="file-input" accept="image/png, image/jpeg" /></IonFabButton>
                  </IonFabList>
                </IonFab>               
              </div>
              <hr style={hrstyle}/>
              {this.state.edit ? <IonTextarea className="user-edit" id="user-desc" value={this.state.Bio} placeholder="Biografia (opzionale)"></IonTextarea> :
              <h3>{(Settings.Bio !== null && Settings.Bio !== "") ? this.state.Bio : "Nessuna biografia"}</h3>}
            </span>
          );
        })} 
    <hr style={hrstyle}/>
    <IonList id="labels-list" className="trasparentBGshadow">
          <IonListHeader>
          <IonLabel id="prefs-label">Preferenze</IonLabel>
          </IonListHeader>
          <IonItem key='semail' className="trasparentBGshadow prefs">
            <IonLabel>Email</IonLabel>
            <IonInput value={this.state.Email}></IonInput>
          </IonItem>
          <IonItem key='supdate' button className={"trasparentBGshadow prefs blue "+((this.state.update) ? "bg-blue" : "")} onClick={(this.state.update) ? UpdateApp : this.checkUpdates.bind(this)}>
            <IonIcon className="blue" ios={reloadOutline} md={reloadOutline} />
            <IonLabel>{(this.state.update) ? "Install Update" : "Check Updates"}</IonLabel>
          </IonItem>
            <IonItem key={'scolour'} className="trasparentBGshadow prefs" routerLink={"/page/UserSettings/Colors"} detail>
              <IonIcon icon={colorPalette} slot='start'></IonIcon>
              <IonLabel><b>Colore principale</b></IonLabel>
            </IonItem>
          {this.SettingsCategories.map((Category, index) => {
            return(
            <IonItem key={'c'+index} className="trasparentBGshadow prefs" routerLink={"/page/UserSettings/"+Category.name} detail>
              <IonIcon icon={Category.icon} slot='start'></IonIcon>
              <IonLabel><b>{Category.name}</b></IonLabel>
            </IonItem>
            )
          })}
        <IonItem button className="trasparentBGshadow prefs pref-logout red" onClick={LogOutUser}>
          <IonIcon className="red" ios={logOutOutline} md={logOutOutline} />
          <IonLabel>
            Log Out
          </IonLabel>
        </IonItem>
      </IonList>
    </IonContent>
    )
  }
}

export class LoadUserSettingsDetails extends React.Component<{ Category: string }> {
  state = { data: [], settings: []};

  Category = this.props.Category;

  SettingsLocalization = { 
    ITA : { "Chat.SendOnEnter" : "Invia con tasto Invio" },
    ENG : { "Chat.SendOnEnter" : "Send whit key Enter" }  
  };

  componentDidMount(){
    this.setState({ settings : JSON.parse(ReadSetting("UserSettings")) });
  }

  UpdateSetting(event, SetName){
    const newValue = event.target.checked;
    EditUserSetting(SetName, newValue);
    this.setState({ settings : JSON.parse(ReadSetting("UserSettings")) });
  }

  render(){
    const hrstyle: React.CSSProperties = {backgroundColor: 'white', marginTop: 0};
    const Settings = this.state.settings;
    return(
      <IonContent class="settings" key="main-settings">        
        <IonList id="labels-list" className="trasparentBGshadow">
            <IonListHeader>
            <IonLabel id="prefs-label">Preferenze</IonLabel>
            </IonListHeader>
          {Object.keys(Settings).map((Name, index) => {
            //const Name = Object.keys(Setting)[0];
            // Mostro solo i settaggi che iniziano con il nome della categoria
            if(this.Category == Name.substring(0, this.Category.length)){
              const value = (Settings[Name] == 0) ? false : true;
              return (
                <IonItem key={'s'+index} className="trasparentBGshadow prefs">
                  <IonLabel>{this.SettingsLocalization["ITA"][Name]}</IonLabel>
                  <IonToggle slot="end" checked={value} onIonChange={(e) => this.UpdateSetting(e, Name)} ></IonToggle>
                </IonItem> 
              );
            }
          })}
        </IonList>
    </IonContent>
    )
  }
}

export class LoadUserSettingsColors extends React.Component {
  state = { data: [], color: []};

  /*SettingsLocalization = { 
    ITA : { "Chat.SendOnEnter" : "Invia con tasto Invio" },
    ENG : { "Chat.SendOnEnter" : "Send whit key Enter" }  
  };*/

  componentDidMount(){
    const setting = JSON.parse(ReadSetting("UserSettings"));
    this.setState({ color : ReadUserSetting("Color") });
  }

  UpdateColor(color, event){
    const newValue = color.hex;
    EditUserColor(newValue);
    this.setState({ color : ReadUserSetting("Color") });
  }

  render(){
    const hrstyle: React.CSSProperties = {backgroundColor: 'white', marginTop: 0};
    return(
      <IonContent class="settings" key="main-settings">        
        <IonList id="labels-list" className="trasparentBGshadow">
            <IonListHeader>
            <IonLabel id="prefs-label">Colori</IonLabel>
            </IonListHeader>
            <CirclePicker width={"95%"} circleSize={40} color={(this.state.color == null)? "#ffffff" : this.state.color} onChangeComplete={(color, event) =>  this.UpdateColor(color, event) } />          
        </IonList>
    </IonContent>
    )
  }
}