import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
//import { send, sendOutline } from 'ionicons/icons';
import './Chat.css';
import LoadChat from '../components/loadChat';
import Chatter from '../components/Chatter';
import { useState, useEffect, useCallback } from 'react';
import Carousel, { Modal, ModalGateway } from "react-images";
import { QueryClient, QueryClientProvider } from 'react-query';

interface UserDetailPageProps extends RouteComponentProps<{
  [gid: string]: string;
}> {}

const Chat: React.FC<UserDetailPageProps> = ({match}) => {

  const name = "Chat";
  const [NewMSG, setNewMSG] = useState<boolean>(false);
  const [ToShare, setToShare] = useState<any>(false);
  var Ifmsg = false;
  function SendMessage() {
      //alert(MsgTXT);
      setNewMSG(true);
      Ifmsg=true;
  }
  function isUpdated() {
    Ifmsg=false;
    setNewMSG(false);
  }
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', function handler (event) {
      console.log(event.data);
      setNewMSG(true);     
    });
  }
  useEffect(() => {
    async function CheckParams() {
      const parsedUrl = new URL(window.location.href);
      const src = parsedUrl.searchParams.get('url');
      if (src != undefined && src != ""){
        const cache = await caches.open('media');
        const response = await cache.match(src);
        const blob = await response.blob();
        const file = new File([blob], src, {
          type: response.headers.get('content-type'),
        });
        const url = URL.createObjectURL(file);
        if(ToShare == false && src == file.name){
          setToShare(url);
        }
      } else if (ToShare != false && !(src != undefined && src != "")) {
        setToShare(false);
      }
    }
    
    CheckParams();
  })

  // Image Viewer Section
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState<any>([{src: ""}]);

  const LoadPhotos = (value) => {
    setPhotos(value);
  };

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const queriesOptions = {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000*60*5,
    },
  };
  const queryClient = new QueryClient({ defaultOptions: queriesOptions, });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton color='light' />
          </IonButtons>
          <IonTitle color='light'>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <QueryClientProvider client={queryClient}>
          <LoadChat group={match.params.gid} newMessage={NewMSG} isUpdated={isUpdated} onImg={openLightbox} definePhotos={LoadPhotos} viewImg={viewerIsOpen} />
        </QueryClientProvider>
          
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  source: x.src,
                  caption: x.caption
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </IonContent>
      <Chatter group={match.params.gid} newMessage={SendMessage} share={ToShare} />
    </IonPage>
  );
};

export default Chat;
