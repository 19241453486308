// Pushy Functions
import Pushy from 'pushy-sdk-web';
import { ReadSetting } from './LocalData';

export function PushySubscribe(topic) {
    // Check if the device is registered
    if (Pushy.isRegistered()) {
    // Subscribe the device to a topic
        Pushy.subscribe(topic).catch(function (err) {
            // Handle subscription errors
            console.error('Subscribe failed:', err);
        });
    }
}

export function PushyUnsubscribe(topic) {
    // Check if the device is registered
    if (Pushy.isRegistered()) {
    // Subscribe the device to a topic
        Pushy.unsubscribe(topic).catch(function (err) {
            // Handle subscription errors
            console.error('Subscribe failed:', err);
        });
    }
}

export function PushySend(text, group) {    
    //const pushyToken = ReadSetting("pushyToken");
    const username = ReadSetting("UserName");

    const pushyrequestOptions = {
    method: 'POST',
    headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded',}),
    body: "title=chatmsg&text="+text+"&group="+group+"&sender="+username//"&token="+pushyToken
    };
    fetch('https://api.vpwi.it/pushy/', pushyrequestOptions);
}

export function PushyMatchUser() {
    // Check if the device is registered
    if (Pushy.isRegistered()) {
        const pushyToken = ReadSetting("pushyToken");
        const username = ReadSetting("UserName");
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                }),
            body: "req=addUserPushy&uid="+username+"&pid="+pushyToken
        };  
            
        fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));    
    }
}

export function deletePushyMatchUser() {
    // Check if the device is registered
    if (Pushy.isRegistered()) {
        const pushyToken = ReadSetting("pushyToken");
        const username = ReadSetting("UserName");
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                }),
            body: "req=delUserPushy&uid="+username+"&pid="+pushyToken
        };  
            
        fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res));    
    }
}