import React, { useRef } from 'react';
import { IonItem, IonInput, IonLabel, IonIcon, IonImg, IonTextarea } from '@ionic/react';
import { send, sendOutline, imageOutline, closeCircleOutline } from 'ionicons/icons';
import Compressor from 'compressorjs';
import { ReadSetting, ReadUserSetting } from '../utils/LocalData';
import { PushySend } from '../utils/Pushy';

export default class Chatter extends React.Component<{ newMessage, group:string, share }> {
  constructor(props){
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onImage = this.onImage.bind(this);
    this.onSelectImage = this.onSelectImage.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)    
  }
  
  state = { inputValue: '', imageInput: null, fileLoaded: null };

  onSubmit(e) {
    e.preventDefault();
    var text = this.state.inputValue.trim();
    const image = this.state.imageInput.files[0];
    const hasImageVar = (image == null) ? '0' : '1';
    if (text != '' || hasImageVar == '1') {
      const msg = new FormData();
      msg.append('req', "sendMessage");
      msg.append('sender', ReadSetting("UserName"));
      msg.append('group', this.props.group);
      msg.append('message', text);
      msg.append('hasImage', hasImageVar);
      if (hasImageVar == '1') {
        //msg.append('imgs', image);
        const thisProps = this.props;
        new Compressor(image, {
          quality: 0.8,
          convertSize: 2000000,
          maxWidth: 1920,
      
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
      
            // The third parameter is required for server
            // @ts-ignore: La proprietà 'name' non esiste nel tipo 'Blob
            msg.append('imgs', result, result.name);
            const requestOptions = {
              method: 'POST',
              body: msg
              //body: "req=sendMessage&json="+jsonmsg
            };
            fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions)
            .then(() => {                
                thisProps.newMessage();
                console.log(text);
              }
            );  
          },
          error(err) {
            console.log(err.message);
          },
        });
      } else {
        const requestOptions = {
          method: 'POST',
          body: msg
          //body: "req=sendMessage&json="+jsonmsg
        };
        fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions)
        .then(() => {                
            this.props.newMessage();
            console.log(text);
          }
        );
      }
      /*const msg = {
        sender: this.props.user,
        message: text,
        hasImage: hasImageVar,
        imgs: image
      };
      var jsonmsg= JSON.stringify(msg);*/

      var inputfield = document.getElementById("file-input") as HTMLInputElement;
      inputfield.value = "";
      this.state.imageInput.value = null;
      this.setState({ inputValue: '', imageInput: null, fileLoaded: null });
      document.getElementById("prev-img").style.display = "none";
      document.getElementById("img-btn").style.display = "block";

      if (text == '' && hasImageVar == '1'){
        text = "📷 Immagine";
      }
      PushySend(text, this.props.group);
    }
  }

  handleKeyPress(event) {
    const SoE = (ReadUserSetting("Chat.SendOnEnter") == false) ? false : true;
    if(event.key === 'Enter' && SoE){
      console.log('enter press here! ')
      this.onSubmit(event);
    }
  }

  onImage() {
    this.state.imageInput?.click();
  }
  onSelectImage(event) {    
    this.setState({
      fileLoaded: URL.createObjectURL(event.target.files[0])
    })
    document.getElementById("img-btn").style.display = "none";
    document.getElementById("prev-img").style.display = "block";
  }
  removeImage() {
    this.setState({
      fileLoaded: null
    })
    document.getElementById("prev-img").style.display = "none";
    document.getElementById("img-btn").style.display = "block";
    this.state.imageInput.value = null;
  }

  updateInputValue(evt) {
    this.setState({ inputValue: evt.target.value });
  }

  componentDidUpdate(prevProps: Readonly<{ newMessage: any; group: string; share: any; }>, prevState: Readonly<{}>, snapshot?: any): void {      
    if (this.props.share != false) {
      if(this.props.share != prevProps.share){
        this.setState({
          fileLoaded: this.props.share
        })
      }
      document.getElementById("img-btn").style.display = "none";
      document.getElementById("prev-img").style.display = "block";
    }
  }

  componentDidMount(){
  }

  render(){    
    return(
      <IonItem>
        <IonTextarea autocapitalize="sentences" name="text" value={this.state.inputValue} onIonChange={evt => this.updateInputValue(evt)} onIonFocus={this.props.newMessage} onKeyPress={this.handleKeyPress} ></IonTextarea>
        <IonIcon slot="end" class="chat-btn allowEvent" ios={imageOutline} md={imageOutline} onClick={this.onImage} id="img-btn" />
        <div id="prev-img" className="chat-btn img-prev" onClick={this.removeImage} style={{display: "none"}}>
          <IonIcon class="chat-btn allowEvent" id="del-img" ios={closeCircleOutline} md={closeCircleOutline} />
          <IonImg src={this.state.fileLoaded} />
        </div>
        
        <IonIcon slot="end" class="chat-btn allowEvent" ios={send} md={send} onClick={this.onSubmit} />
        {/* <IonLabel slot="end" id="chat-btns"></IonLabel> */}
        <input ref={(el) => { this.state.imageInput = el; }} onChange={this.onSelectImage} hidden type="file" id="file-input" accept="image/png, image/jpeg" />
      </IonItem>
    )
  }
} 