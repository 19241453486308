import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, IonIcon, IonSearchbar, IonLabel, IonAvatar, IonImg } from '@ionic/react';
import Kilo from '../img/zXHkOvRlRppzIgzAJy65_img-20190223-222952-largejpg.jpg';
import Piada from '../img/ci-sono-tavoli-conviviali.jpg';
import Tostiamo from '../img/toast2-1024x682.jpg';
import { add } from 'ionicons/icons';
import './Cibo.css';
import LoadCibo from '../components/loadCibo';

const Cibo: React.FC = () => {

  const name = "Cibo";

  let map: google.maps.Map;
  // Create the places service.
  const service = new google.maps.places.PlacesService(map);
  /* let getNextPage: () => void | false;
  const moreButton = document.getElementById("more") as HTMLButtonElement;

 moreButton.onclick = function () {
    moreButton.disabled = true;

    if (getNextPage) {
      getNextPage();
    }
  };*/

 
  function initMap() {

    var request = {
      placeId: 'ChIJU-qGqRFhLxMRcHFTXxfdyfw',
      //query: 'Museum of Contemporary Art Australia',
      fields: ['name', 'geometry', 'photos'],
    };

    var service = new google.maps.places.PlacesService(document.createElement('div'));

    
    //service.findPlaceFromQuery(request,
    service.getDetails(request, function(result, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        //for (var i = 0; i < results.length; i++) {
          //createMarker(results[i]);
          console.log(result);
        //}
      }
    });
  }
 initMap();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
          <IonButtons slot="primary">
            <IonButton color="white" routerLink="/page/Cibo/Add">
              <IonIcon slot="icon-only" ios={add} md={add} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
          <IonSearchbar placeholder="Cerca" animated ></IonSearchbar>
          <LoadCibo/>
      </IonContent>
    </IonPage>
  );
};

export default Cibo;
