import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonCol, IonRow, IonImg, IonLabel, IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { useState, useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import './Gallery.css';
import { add, addOutline } from 'ionicons/icons';
import { ReadSetting } from '../utils/LocalData';
import calculateAspectRatio from 'calculate-aspect-ratio';
import Compressor from 'compressorjs';
import { GalleryLocalization } from '../utils/Localization';

var uploaded = 0;
interface GalleryPageProps extends RouteComponentProps<{
  [gid: string]: string;
}> {}

const Page: React.FC<GalleryPageProps> = ({match}) => {
  const [photos, setPhotos] = useState<any>([{src: "", width: 0, height: 0}]);
  const [gid] = useState<any>((match.params.gid != undefined) ? match.params.gid : new URL(window.location.href).pathname.split('/')[2]);

  const [uploadStatus, setUploadStatus] = useState<any>({uploaded: 0, toUpload: 0});

  //const gid = (match.params.gid != undefined) ? match.params.gid : new URL(window.location.href).pathname.split('/')[2];

  useEffect(() => {
    LoadImages();
  }, [])

  function LoadImages() {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getGallery&group="+gid
    };
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then(response => response.json())
    .then(data => setPhotos(data));
  }
  
  /*const photos = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
      width: 1,
      height: 1
    }]*/

  // Image Viewer Section
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // Upload Image Section
  const [imageInput, setImageInput] = useState<any>(null);  

  function onImage() {
    imageInput?.click();
  }
  function clearUpload() {
    setTimeout(() => {
      setUploadStatus({uploaded: 0, toUpload: 0});
      uploaded = 0;      
    }, 3000);
  }
  function onSelectImage(event) {
    if (event.target.files.length > 0) {
      Array.prototype.forEach.call(event.target.files, function(UpImage) { 
        var Ratio;
        var img = new Image();
        img.src = URL.createObjectURL(UpImage);
        img.onload = function()
        {
          //@ts-ignore
          Ratio = calculateAspectRatio(this.width, this.height);
        }
        img.remove();
        new Compressor(UpImage, {
          quality: 0.8,
          convertSize: 2000000,
          maxWidth: 1920,
      
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
              const formData = new FormData();

              // The third parameter is required for server
              // @ts-ignore: La proprietà 'name' non esiste nel tipo 'Blob
              formData.append('img', result, result.name);
              formData.append('width', Ratio.split(':')[0]);
              formData.append('height', Ratio.split(':')[1]);
              formData.append('user', ReadSetting("UserName"));
              formData.append('group', gid);
              formData.append('req', 'addGalleryImage');

              const requestOptions = {
                  method: 'POST',
                  body: formData
              };
              setUploadStatus({uploaded: uploaded, toUpload: event.target.files.length});
              fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res)).then( () => { uploaded++; setUploadStatus({uploaded: uploaded, toUpload: event.target.files.length}); LoadImages(); } );
          },
          error(err) {
              console.log(err.message);
          },
        });
      });
    } /*else {
    const UpImage = event.target.files[0];
    var Ratio;
    var img = new Image();
    img.src = URL.createObjectURL(UpImage);
    img.onload = function()
		{
      //@ts-ignore
			Ratio = calculateAspectRatio(this.width, this.height);
		}
		img.remove();
    new Compressor(UpImage, {
      quality: 0.8,
      convertSize: 2000000,
  
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
          const formData = new FormData();

          // The third parameter is required for server
          // @ts-ignore: La proprietà 'name' non esiste nel tipo 'Blob
          formData.append('img', result, result.name);
          formData.append('width', Ratio.split(':')[0]);
          formData.append('height', Ratio.split(':')[1]);
          formData.append('user', ReadSetting("UserName"));
          formData.append('group', gid);
          formData.append('req', 'addGalleryImage');

          const requestOptions = {
              method: 'POST',
              body: formData
          };    
          fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.text()).then((res) => console.log(res)).then( () => { LoadImages(); } );
      },
      error(err) {
          console.log(err.message);
      },
    });} */
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Gallery</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Gallery</IonTitle>
          </IonToolbar>
        </IonHeader>
        {(photos) ? <div>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  source: x.src
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
       </div> : <IonLabel>Non ci sono ancora immagini, premi + e comincia ad aggiungerle</IonLabel> }
       {(uploadStatus.toUpload != 0) ? (uploadStatus.toUpload != uploadStatus.uploaded) ? <div id='UploadingStatus'>{GalleryLocalization()['UploadingStatus.Running']} {uploadStatus.uploaded+"/"+uploadStatus.toUpload} </div> : <div id='UploadingStatus'>{GalleryLocalization()['UploadingStatus.Done']}{clearUpload()}</div> : null}
       <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => onImage() }>
            <IonIcon ios={addOutline} md={add} />
            <input ref={(el) => { setImageInput(el); }} onChange={onSelectImage.bind(this)} hidden type="file" id="file-input" accept="image/png, image/jpeg" multiple />
          </IonFabButton>
       </IonFab>  
      </IonContent>
    </IonPage>
  );
};

export default Page;
