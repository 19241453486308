import React, { useRef } from 'react';
import { IonItem, IonInput, IonLabel, IonIcon, IonImg, IonTextarea, IonText, IonAvatar } from '@ionic/react';
import { send, sendOutline, imageOutline, closeCircleOutline, arrowBack, arrowForward } from 'ionicons/icons';
import { ReadSetting, WriteSetting } from '../utils/LocalData';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import { GetGroupEvents, GetGroupEventsAdvanced } from '../utils/Events';
import Telegramlogo from '../img/zrItJqWTRXGNZJ2OFfwf_telegram.jpg';

export function SyncWidgets(){    
  const requestOptions2 = {
      method: 'POST',
      headers: new Headers({
                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
      body: "req=getWidgets"
    };
    return fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions2)
    .then(response => response.json())
    .then(widgets => WriteSetting("Widgets", JSON.stringify(widgets)));
}

export const WeatherWidget: React.FC = () => {

    const { data, isLoading, errorMessage } = useOpenWeather({
        key: '20ca99100b0ddbf20cafcfc9b16ac1c5',
        lat: '41.9027835',
        lon: '12.4963655',
        lang: 'it',
        unit: 'metric', // values are (metric, standard, imperial)
    });

  return(
    <WeatherWidgetClass isLoading={isLoading} errorMessage={errorMessage} data={data} />
  )

}

export class WeatherWidgetClass extends React.Component<{ isLoading, errorMessage, data }> {
  constructor(props){
    super(props);
    
  }
  
  //state = { isLoading: '', errorMessage: null, data: null };

  componentDidMount(){      
  }

  render(){    
    return(
        <IonItem className='hm-half trasparentBGshadow react-grid-item-content'>
            <ReactWeather
            isLoading={this.props.isLoading}
            errorMessage={this.props.errorMessage}
            data={this.props.data}
            lang="it"
            locationLabel="Roma"
            unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
            showForecast={false}
            />
      </IonItem>
    )
  }
} 

var eventNum = 0;
export class EventWidget extends React.Component<{ gid }> {
  constructor(props){
    super(props);
    
    this.LoadNextEvents = this.LoadNextEvents.bind(this);
    this.LoadPrevEvents = this.LoadPrevEvents.bind(this);
  }
  
  state = { events: undefined };


  async componentDidMount(){
    eventNum = 0;
    this.setState ({ events: await GetGroupEventsAdvanced(this.props.gid, eventNum) });
  }

  async LoadNextEvents(){
    eventNum++;
    this.setState ({ events: await GetGroupEventsAdvanced(this.props.gid, eventNum) });
  }

  async LoadPrevEvents(){
    eventNum--;
    this.setState ({ events: await GetGroupEventsAdvanced(this.props.gid, eventNum) });
  }

  DaysText(){
    switch (eventNum) {
      case 0:
        return "di oggi";
      case 1:
        return "di domani";
      case -1:
        return "di ieri";
      default:
        var date = new Date();
        date.setDate(date.getDate() + eventNum);
        return "del "+ date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();;
    } 

  }

  render(){    
    return(
        <div className='hm-half react-grid-item-content'>
            <IonItem className='trasparentBGshadow'>
            <IonLabel><h1>Eventi {this.DaysText()}</h1></IonLabel>
            <IonIcon icon={arrowBack} onClick={this.LoadPrevEvents} slot="start"/>
            <IonIcon icon={arrowForward} onClick={this.LoadNextEvents} slot="end"/>
            </IonItem>
            <IonText><ul>
            {(this.state.events == undefined) ? "Nessun evento" : (this.state.events.length == 0) ? "Nessun evento" : this.state.events.map(ev => {
                return(
                <li key={"ev"+ev.id}>{(this.props.gid == 'user')? ev.GroupName+' - '+ev.name : ev.name}</li>
                )
            })
            }</ul>
            </IonText>
        </div>
    )
  }
} 
export class TelegramWidget extends React.Component<{ }> {
  constructor(props){
    super(props);
    
  }  
  
  async componentDidMount(){
  }

  render(){    
    return(
        <IonItem className='hm-full react-grid-item-content'>
            <IonAvatar>
                <IonImg src={Telegramlogo} alt="Telegram"/>
            </IonAvatar>
            <IonLabel><h1>Telegram</h1></IonLabel>
        </IonItem>
    )
  }
}
export interface Widget {
  i: string;
  h: number;
  w: number;
  x: number;
  y: number;
  moved: boolean;
  static: boolean;
  minW?: number;
  minH?: number;
  maxW?: number;
  maxH?: number;
}