import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonReorder,
  IonReorderGroup,
} from '@ionic/react';

import { Link, useLocation } from 'react-router-dom';
import { images, imagesOutline, bookmarkOutline, codeWorkingOutline, codeWorking, homeOutline, home, chatboxOutline, chatbox, gameControllerOutline, gameController, fastFoodOutline, fastFood, calendar, calendarOutline, logOutOutline, settingsOutline, add, construct, personOutline } from 'ionicons/icons';
import './Menu.css';
import { ReadSetting, WriteSetting } from '../utils/LocalData';
import {useQuery,useMutation,useQueryClient,QueryClient,QueryClientProvider} from 'react-query'
import { PushySubscribe } from '../utils/Pushy';
import { useState } from 'react';
import { ItemReorderEventDetail } from '@ionic/core';
import { useLongPress } from 'use-long-press';
import { EditGroupOrder } from '../utils/Groups';

interface AppPage {
  id: string;
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/page/:id/Home',
    iosIcon: homeOutline,
    mdIcon: home,
    id: 'h'
  },
 /* {
    title: 'Cibo',
    url: '/page/:id/Cibo',
    iosIcon: fastFoodOutline,
    mdIcon: fastFood
  },*/
  {
    title: 'Calendario',
    url: '/page/:id/Calendario/',
    iosIcon: calendarOutline,
    mdIcon: calendar,
    id: 'd1'
  },
  {
    title: 'Chat',
    url: '/page/:id/Chat/',
    iosIcon: chatboxOutline,
    mdIcon: chatbox,
    id: 'd2'
  }/*,
  {
    title: 'Gallery',
    url: '/page/:id/Gallery',
    iosIcon: imagesOutline,
    mdIcon: images
  },
  {
    title: 'Games',
    url: '/page/:id/Games',
    iosIcon: gameControllerOutline,
    mdIcon: gameController
  },
  {
    title: 'Progetti',
    url: '/page/:id/Progetti',
    iosIcon: codeWorkingOutline,
    mdIcon: codeWorking
  }*/
];

const addUrl = "/page/:id/AddSection/"
const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
const queriesOptions = {
  queries: {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 1000*60*5,
  },
};
var orderTable = [];

const queryClient = new QueryClient({ defaultOptions: queriesOptions, });
var reorderGroup = null;
var tmpGroupOrder = null;
function LoadGroups(){
  const location = useLocation();
  const queryClient2 = new QueryClient({ defaultOptions: queriesOptions, });
  const gid = parseInt(location.pathname.split('/')[2]);
  const [ActiveGroup, setActiveGroup] = useState<number>(gid);
  const [reorder, setReorder] = useState<boolean>(false);
  //var reorder = false;
  const onLongPress = useLongPress((e) => {
    e.stopPropagation();
    e.preventDefault();
    if(reorder){
      EditGroupOrder(gid, tmpGroupOrder);
      tmpGroupOrder=null;
    }
    reorderGroup = ''+ActiveGroup;
    setReorder(!reorder);
    console.log('Long pressed!');
  }, { cancelOnMovement: true, captureEvent: true });
  const SetOrder = function (newOrder, gid) {
    orderTable.find(x => x.gid === gid).order = newOrder;
  }

  const requestOptions = {
    method: 'POST',
    headers: new Headers({
               'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
    body: "req=getGroups&user="+ReadSetting("UserName")
  };  

  const queryClient = useQueryClient();
  const { status, data, error, isFetching, isLoading } = useQuery("groups", () => 
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json())
  );
  if (isLoading) return (<IonLabel>Loading...</IonLabel>);

  if (error) return (<IonLabel>{"An error has occurred: "+ error}</IonLabel>);

  return (
    <div>
    {(data.length > 0) ? data.map((group) => (
      <div onClick={() =>setActiveGroup(group.id)} className={(ActiveGroup==group.id||parseInt(location.pathname.split('/')[2])==group.id) ? "menu menu-selected":"menu"} style={{ background: `url(${ ( group.image !== null) ? "https://api.vpwi.it/cugust/assets/img/group-imgs/"+group.image : "/assets/img/bg.png"})`, backgroundRepeat: `${(group.image !== null) ? 'no-repeat' : 'repeat'}`, backgroundSize: `${(group.image !== null) ? 'cover' : 'auto'}` }}>
      <IonItem lines="none" className="trasparentBGshadow" key={'g'+group.id} > {/*routerLink={"/page/Chat/"+group.id}*/}
        <IonIcon slot="start" icon={bookmarkOutline} />
        <IonLabel>{group.GroupName}</IonLabel>
        <Link slot="end" to={"/page/"+group.id+"/GroupSettings/"}> <IonIcon slot="end" ios={settingsOutline} md={settingsOutline} className="hover-red" ></IonIcon> </Link>
        {WriteSetting(group.id+"-layouts", group.layouts)}
        {PushySubscribe("g"+group.id)}
        {collectOrders(group.id, JSON.parse(group.order).menu)}
      </IonItem>
      <IonList id={'list-'+group.id} className={`group-list trasparentBGshadow ${(ActiveGroup==group.id) ? "group-list-open":null}`}>
      <IonReorderGroup disabled={(!reorder || reorderGroup != group.id)} onIonItemReorder={doReorder} >{/*
      {appPages.map((appPage, index) => {
          return (
              <IonReorder>
            <IonMenuToggle key={'p'+group.id+index} autoHide={false} {...onLongPress}>
              <IonItem className={location.pathname === appPage.url.replace(":id", group.id) ? 'selected' : 'trasparentBGshadow'} routerLink={appPage.url.replace(":id", group.id)} routerDirection="none" lines="none" detail={false}>
                <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                <IonLabel>{appPage.title}</IonLabel>
              </IonItem>
            </IonMenuToggle>
              </IonReorder>
          );
        })}
        <QueryClientProvider client={queryClient2}>
            <LoadGroupAddons gid={group.id} onLongPress={onLongPress} />
      </QueryClientProvider>  */}
        <QueryClientProvider client={queryClient2}>
            <LoadGroupMenu gid={group.id} onLongPress={onLongPress} order={JSON.parse(group.order).menu} SetOrder={SetOrder} />
        </QueryClientProvider>
      </IonReorderGroup>      
        <IonMenuToggle key={'add'+group.id} autoHide={false}>
          <IonItem className={location.pathname === addUrl.replace(":id", group.id) ? 'selected' : 'trasparentBGshadow'} routerLink={addUrl.replace(":id", group.id)} routerDirection="none" lines="none" detail={false}>
            <IonIcon slot="start" ios={add} md={add} />
            <IonLabel>Aggiungi</IonLabel>
          </IonItem>
        </IonMenuToggle>
      </IonList>
    </div>
    )) : <IonItem lines="none"><IonLabel>Non fai ancora parte di nessun gruppo!</IonLabel></IonItem> }
    </div>
  );
}

function LoadGroupMenu(params) {
  const location = useLocation();
  const icons = require('ionicons/icons');
  const gid = params.gid;
  var order = params.order;
  var MenuObjects = appPages;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
               'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
    body: "req=getGroupAddons&group="+gid
  };  

  const { status, data, error, isFetching, isLoading } = useQuery(["addons", gid], () => 
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json())
  );
  
  if (data !== undefined) data.map((Addon, index) => {
    MenuObjects.push({ id: 'a'+Addon.id, url: '/page/:id/'+Addon.Path, iosIcon: eval("icons."+Addon.Icon), mdIcon: eval("icons."+Addon.Icon), title: Addon.Name});
    if (order.find(x => x === 'a'+Addon.id) == undefined) { order.push('a'+Addon.id); params.SetOrder(order, gid);  }
  });

  return (
    order.map((Entry, index) => {
      if (data == undefined && Entry.substring(0,1) == 'a') return;
      const item = MenuObjects.find(x => x.id === Entry);
      return (
        <IonReorder>
          <IonMenuToggle key={'ap'+gid+item.id+index} autoHide={false} {...params.onLongPress}>
            <IonItem about={gid} className={location.pathname === (item.url).replace(":id", gid) ? 'selected' : 'trasparentBGshadow'} routerLink={(item.url).replace(":id", gid)} routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" ios={item.iosIcon} md={item.mdIcon} />
              <IonLabel>{item.title}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonReorder>
      );
    })
  );
}

function collectOrders(gid, order){
  if(orderTable.find(x => x.gid === gid) == undefined) orderTable.push({ gid: gid, order: order});
}

function LoadGroupAddons(params) {
  const location = useLocation();
  const icons = require('ionicons/icons');
  const gid = params.gid;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
               'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
    body: "req=getGroupAddons&group="+gid
  };  

  const { status, data, error, isFetching, isLoading } = useQuery(["addons", gid], () => 
    fetch('https://api.vpwi.it/cugust/apisql.php', requestOptions).then((res) => res.json())
  );

  return (
    (data !== undefined) ? data.map((Addon, index) => {
      return (
        <IonReorder>
          <IonMenuToggle key={'ap'+gid+Addon.id+index} autoHide={false} {...params.onLongPress}>
            <IonItem className={location.pathname === ('/page/:id/'+Addon.Path).replace(":id", gid) ? 'selected' : 'trasparentBGshadow'} routerLink={('/page/:id/'+Addon.Path).replace(":id", gid)} routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" ios={eval("icons."+Addon.Icon)} md={eval("icons."+Addon.Icon)} />
              <IonLabel>{Addon.Name}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonReorder>
      );
    }) : null
  );
}
//var items = ["Home", "Calendario", "Chat", "Cibo", "Galleria", "Games"];
function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
  if (tmpGroupOrder === null) { tmpGroupOrder = orderTable.find(x => x.gid === reorderGroup).order;}
  console.log('Before complete', tmpGroupOrder);

  // The `from` and `to` properties contain the index of the item
  // when the drag started and ended, respectively
  console.log('Dragged from index', event.detail.from, 'to', event.detail.to);

  if(event.detail.from == 0 || event.detail.to == 0){
    console.log("Tentativo di spostare Home");
    event.detail.complete(false);
  } else {
    let draggedItem = tmpGroupOrder.splice(event.detail.from,1)[0];
    tmpGroupOrder.splice(event.detail.to,0,draggedItem);  
    console.log('After complete', tmpGroupOrder);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  }
}

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list" className="trasparentBGshadow" style={("UserImage" in localStorage) ? {paddingBottom: 0, backgroundImage: 'url('+ReadSetting("UserImage")+')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }  : {paddingBottom: 0, backgroundImage: 'url(/assets/img/bg.png)', backgroundRepeat: 'repeat', backgroundSize: 'auto' } }>
          <div className='menu-user' key={'User'}>
            <IonListHeader>{ReadSetting("UserName")}</IonListHeader>
            <IonNote>{ReadSetting("Email")}</IonNote>
            <Link to="/page/UserSettings"><IonIcon className="usersetting-icon" ios={settingsOutline} md={settingsOutline} /></Link>
          </div>
          <IonItem key={'UserHome'} className={location.pathname === '/page/user/Home' ? 'selected' : 'trasparentBGshadow'} routerLink='/page/user/Home' routerDirection="back" lines="none" detail={false}>
            <IonIcon slot="start" ios={personOutline} md={personOutline} />
            <IonLabel>Home</IonLabel>
          </IonItem>
          {/*  appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })  */}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader key={'menuHeader'}>
            <IonLabel>Groups</IonLabel>
            <IonButton color="dark" className="add-button" routerLink="/page/Group/Add">
              <IonIcon slot="icon-only" ios={add} md={add} />
            </IonButton>
          </IonListHeader>
          {/* {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))} */}
          <QueryClientProvider client={queryClient}>
            <LoadGroups />
          </QueryClientProvider>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
